import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '../../../services/http-error.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { EndpointService } from '../../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../../enums/http-operation';
import { AbsenceReasonModel } from '../../../models/absence-reason/absence-reason.model';
import * as fromAbsenceReasonsActions from './absence-reasons.actions';

@Injectable()
export class AbsenceReasonsEffects {
  fetchAbsenceReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAbsenceReasonsActions.fetchAbsenceReasons),
      switchMap(() => {
        const endpoint = this.endpointService.getAbsenceReasonsSlug(HttpOperation.Get);
        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown[]) => {
            const absenceReasons = plainToInstance(AbsenceReasonModel, json);
            return fromAbsenceReasonsActions.setAbsenceReasons({ payload: absenceReasons });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'AbsenceReasons',
              fromAbsenceReasonsActions.fetchAbsenceReasons,
              fromAbsenceReasonsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addAbsenceReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAbsenceReasonsActions.addAbsenceReason),
      map((action) => action.payload),
      mergeMap((absenceReasons) => {
        const endpoint = this.endpointService.getAbsenceReasonsSlug(HttpOperation.Post);
        return this.http.post<AbsenceReasonModel>(endpoint, absenceReasons).pipe(
          map((absenceReasons) => {
            return fromAbsenceReasonsActions.absenceReasonAdded({ payload: absenceReasons });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'AbsenceReasons',
              fromAbsenceReasonsActions.addAbsenceReason,
              fromAbsenceReasonsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateAbsenceReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAbsenceReasonsActions.updateAbsenceReason),
      map((action) => action.payload),
      mergeMap((absenceReasons) => {
        const endpoint = this.endpointService.getAbsenceReasonsSlug(
          HttpOperation.Put,
          absenceReasons.id,
        );
        return this.http.put<AbsenceReasonModel>(endpoint, absenceReasons).pipe(
          map((absenceReasons) => {
            return fromAbsenceReasonsActions.absenceReasonUpdated({ payload: absenceReasons });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'AbsenceReasons',
              fromAbsenceReasonsActions.updateAbsenceReason,
              fromAbsenceReasonsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteAbsenceReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAbsenceReasonsActions.deleteAbsenceReason),
      map((action) => action.payload),
      mergeMap((absenceReason) => {
        const endpoint = this.endpointService.getAbsenceReasonsSlug(
          HttpOperation.Delete,
          absenceReason.id,
        );
        return this.http.delete(endpoint).pipe(
          map(() => {
            return fromAbsenceReasonsActions.absenceReasonDeleted({ payload: absenceReason });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'AbsenceReasons',
              fromAbsenceReasonsActions.deleteAbsenceReason,
              fromAbsenceReasonsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
