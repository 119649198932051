import { createReducer, on } from '@ngrx/store';
import { setSingleTask, setTasks, taskAdded, taskDeleted, taskUpdated } from './tasks.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ProjectTaskModel } from '../../../models/project/project-task.model';

export interface TaskState extends EntityState<ProjectTaskModel> {}

export const taskAdapter: EntityAdapter<ProjectTaskModel> = createEntityAdapter<ProjectTaskModel>();
const initialState: TaskState = taskAdapter.getInitialState();

export const tasksReducer = createReducer(
  initialState,
  on(setSingleTask, (state, { payload }): TaskState => {
    return taskAdapter.setOne(payload, state);
  }),
  on(setTasks, (state, { payload }): TaskState => {
    return taskAdapter.setMany(payload, state);
  }),
  on(taskAdded, (state, { payload }): TaskState => {
    if (Array.isArray(payload)) {
      return taskAdapter.addMany(payload, state);
    }
    return taskAdapter.addOne(payload, state);
  }),
  on(taskUpdated, (state, { payload }): TaskState => {
    if (Array.isArray(payload)) {
      return taskAdapter.upsertMany(payload, state);
    }
    return taskAdapter.upsertOne(payload, state);
  }),
  on(taskDeleted, (state, { payload }): TaskState => {
    if (Array.isArray(payload)) {
      return taskAdapter.removeMany(
        payload.map((t) => t.id),
        state,
      );
    }
    return taskAdapter.removeOne(payload.id, state);
  }),
);
