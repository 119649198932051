import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeBlockComponent } from './time-block.component';
import { TimeBlockFilterPipe } from './pipes/time-block-filter.pipe';
import { TimeBlockDragResizeDirective } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize.directive';
import { TimeBlockContainerDirective } from '../../views/time-block-container.directive';
import { EndOfDayPipe } from '../../pipes/end-of-day.pipe';
import { CoreModule } from '../../../../core/core.module';
import { SharedModule } from '../../../../shared/shared.module';
import { VerticalDayOrWeekResizeControllerService } from './interaction/movement/drag-and-resize/resizing/resize-view-controller/vertical-day-or-week-resize-controller.service';
import { ResizeTimeBlockScheduleCalculatorService } from './interaction/movement/drag-and-resize/resizing/resize-time-block-schedule-calculator.service';
import { ResizeRowOrLaneSwitchService } from './interaction/movement/drag-and-resize/resizing/resize-row-or-lane-switch.service';
import { ResizeCalculatorService } from './interaction/movement/drag-and-resize/resizing/resize-calculator.service';
import { TimeBlockService } from './services/time-block.service';
import { TimeBlockMappingService } from './time-block-structure/time-block-mapping.service';
import { TimeBlockRenderService } from './rendering/time-block-render.service';
import { TimeBlockHttpService } from '../../../../core/state/time-blocks/time-block-http.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { TimeBlockCrudService } from './crud/time-block-crud.service';
import { TimeBlockComponentHandlerService } from './generation/time-block-component-handler.service';
import { DragDropPositioningService } from './interaction/movement/drag-and-resize/dragging/drag-and-drop/drag-drop-positioning.service';
import { TimeBlockStructureService } from './time-block-structure/time-block-structure.service';
import { CollisionHandlerService } from './interaction/movement/collision-handling/collision-handler.service';
import { DragAndDropHandlerService } from './interaction/movement/drag-and-resize/dragging/drag-and-drop/drag-and-drop-handler.service';
import { TimeBlockDragResizeService } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize.service';
import { TimeBlockItemBuilderService } from './generation/time-block-item-builder.service';
import { TimeBlockHttpTransformationService } from './http/time-block-http-transformation.service';
import { TimeBlockGeometryService } from './calculation/geometry/time-block-geometry.service';
import { DragHandlerService } from './interaction/movement/drag-and-resize/drag-handler.service';
import { HorizontalAllResizeControllerService } from './interaction/movement/drag-and-resize/resizing/resize-view-controller/horizontal-all-resize-controller.service';
import { TimeBlockDragResizeControllerService } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockDragResizeMouseHandlerService } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/time-block-drag-resize-mouse-handler.service';
import { TimeBlockElementSelectorService } from './rendering/time-block-element-selector.service';
import { InteractHandlerService } from './interaction/movement/interact-handler.service';
import { TimeBlockHttpCallbackService } from './http/time-block-http-callback.service';
import { TimeBlockHighlightingService } from './highlighted/time-block-highlighting.service';
import { ParallelTimeBlockGeometryCalculatorService } from './calculation/geometry/parallel-time-block-geometry-calculator.service';
import { TimeBlockColorPipe } from './pipes/time-block-color.pipe';
import { IntersectionService } from './calculation/intersection/intersection.service';
import { FreelyDayOrWeekDragControllerService } from './interaction/movement/drag-and-resize/dragging/drag-view-controller/freely-day-or-week-drag-controller.service';
import { HorizontalDayOrWeekFullDayDragControllerService } from './interaction/movement/drag-and-resize/dragging/drag-view-controller/horizontal-day-or-week-full-day-drag-controller.service';
import { HorizontalMonthFullDayInnerDayDragControllerService } from './interaction/movement/drag-and-resize/dragging/drag-view-controller/horizontal-month-full-day-inner-day-drag-controller.service';
import { DragEdgeTemplateComponent } from './templates/drag-edge-template/drag-edge-template.component';
import { SharedHorizontalDragResizeService } from './interaction/movement/drag-and-resize/time-block-drag-resize-handling/shared-horizontal-drag-resize.service';
import { TimeBlockDialogService } from './services/time-block-dialog.service';
import { TimeBlockAccumulatedWorkPipe } from './pipes/time-block-accumulated-work.pipe';
import { ParallelTimeBlockWeekViewInnerdayCalculatorService } from './calculation/geometry/parallel-time-block-geometry/parallel-time-block-week-view-innerday-calculator-service';
import { ParallelTimeBlockWeekViewFulldayCalculatorService } from './calculation/geometry/parallel-time-block-geometry/parallel-time-block-week-view-fullday-calculator.service';
import { ParallelTimeBlockMonthViewCalculatorService } from './calculation/geometry/parallel-time-block-geometry/parallel-time-block-month-view-calculator.service';
import { HorizontalSlotHandlerService } from './calculation/geometry/parallel-time-block-geometry/horizontal-slot-handler.service';
import { TimeBlockMonthViewContainerRendererService } from './rendering/time-block-month-view-container-renderer.service';
import { TimeBlockTimeDifferencePipe } from './pipes/time-block-time-difference.pipe';
import { TimeBlockBodyComponent } from './templates/time-block-body/time-block-body.component';
import { ActiveTimeBlockTrackerService } from './active/active-time-block-tracker.service';
import { TimeBlockStripedBgImagePipe } from './pipes/time-block-striped-bg-image.pipe';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule],
  declarations: [
    TimeBlockComponent,
    TimeBlockBodyComponent,
    TimeBlockFilterPipe,
    TimeBlockColorPipe,
    TimeBlockStripedBgImagePipe,
    TimeBlockDragResizeDirective,
    TimeBlockContainerDirective,
    EndOfDayPipe,
    DragEdgeTemplateComponent,
    TimeBlockAccumulatedWorkPipe,
    TimeBlockTimeDifferencePipe,
  ],
  providers: [
    VerticalDayOrWeekResizeControllerService,
    ResizeTimeBlockScheduleCalculatorService,
    ResizeRowOrLaneSwitchService,
    ResizeCalculatorService,
    SharedHorizontalDragResizeService,
    FreelyDayOrWeekDragControllerService,
    HorizontalDayOrWeekFullDayDragControllerService,
    HorizontalMonthFullDayInnerDayDragControllerService,
    TimeBlockService,
    TimeBlockDialogService,
    TimeBlockMappingService,
    TimeBlockRenderService,
    TimeBlockHttpService,
    TimeCoordinateMappingService,
    TimeBlockCrudService,
    TimeBlockComponentHandlerService,
    DragDropPositioningService,
    TimeBlockStructureService,
    CollisionHandlerService,
    DragAndDropHandlerService,
    TimeBlockDragResizeService,
    TimeBlockItemBuilderService,
    TimeBlockHttpTransformationService,
    TimeBlockGeometryService,
    DragHandlerService,
    HorizontalAllResizeControllerService,
    TimeBlockMonthViewContainerRendererService,
    TimeBlockDragResizeControllerService,
    TimeBlockDragResizeMouseHandlerService,
    TimeBlockElementSelectorService,
    InteractHandlerService,
    TimeBlockHttpCallbackService,
    TimeBlockHighlightingService,
    ParallelTimeBlockGeometryCalculatorService,
    ParallelTimeBlockWeekViewInnerdayCalculatorService,
    ParallelTimeBlockWeekViewFulldayCalculatorService,
    ParallelTimeBlockMonthViewCalculatorService,
    IntersectionService,
    HorizontalSlotHandlerService,
    ActiveTimeBlockTrackerService,
  ],
  exports: [TimeBlockContainerDirective, TimeBlockAccumulatedWorkPipe],
})
export class TimeBlockModule {}
