import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  public handleError(
    identifier: string,
    actionType: unknown,
    errorType,
    error,
  ): Observable<Action> {
    const message = `Ein allgemeiner Fehler bei den ${identifier}  ist aufgetreten. ${error.message}`;

    return of(
      errorType.httpFail({
        payload: {
          identifier,
          actionType,
          message,
          error,
        },
      }),
    );
  }
}
