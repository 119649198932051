import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProjectsActions from './projects.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { HttpOperation } from '../../enums/http-operation';
import { ProjectModel } from '../../models/project/project.model';
import { EndpointService } from '../../services/endpoints/endpoint.service';
import { HttpErrorService } from '../../services/http-error.service';

@Injectable()
export class ProjectsEffects {
  fetchProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectsActions.fetchProjects),
      map((action) => action.payload),
      switchMap((clientId) => {
        const endpoint = this.endpointService.getProjectsSlug(HttpOperation.Get);

        let params = new HttpParams();
        if (clientId) {
          params = params.append('client_id', clientId);
        }

        return this.http.get<unknown>(endpoint, { params }).pipe(
          map((json: unknown[]) => {
            const projects = plainToInstance(ProjectModel, json);
            return fromProjectsActions.setProjects({ payload: projects });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Projects',
              fromProjectsActions.setProjects,
              fromProjectsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchSingleProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectsActions.fetchSingleProject),
      map((data) => data.payload),
      switchMap((projectId) => {
        const endpoint = this.endpointService.getProjectsSlug(HttpOperation.Get, projectId);

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const project = plainToInstance(ProjectModel, json);
            // Populate tasks with project id.
            project.tasks = project.tasks.map((task) => {
              return {
                ...task,
                projectId,
              };
            });
            return fromProjectsActions.setSingleProject({ payload: project });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromProjectsActions.fetchSingleProject,
              fromProjectsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectsActions.addProject),
      map((action) => action.payload),
      mergeMap((projectModel) => {
        const endpoint = this.endpointService.getProjectsSlug(HttpOperation.Post);
        const httpProjectModel = instanceToPlain(projectModel);
        delete httpProjectModel.id;

        return this.http.post<unknown>(endpoint, httpProjectModel).pipe(
          map((project: ProjectModel) => {
            return fromProjectsActions.projectAdded({ payload: project });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Projects',
              fromProjectsActions.addProject,
              fromProjectsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectsActions.updateProject),
      map((action) => action.payload),
      mergeMap((projectModel) => {
        let requests;
        let endpoint;
        if (Array.isArray(projectModel)) {
          requests = projectModel.map((model) => {
            endpoint = this.endpointService.getProjectsSlug(HttpOperation.Put, model.id);
            return this.http.put(endpoint, instanceToPlain(model));
          });
        } else {
          endpoint = this.endpointService.getProjectsSlug(HttpOperation.Put, projectModel.id);
          requests = [this.http.put(endpoint, instanceToPlain(projectModel))];
        }

        return forkJoin(requests).pipe(
          map((project: ProjectModel[]) => {
            return fromProjectsActions.projectUpdated({ payload: project });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Projects',
              fromProjectsActions.updateProject,
              fromProjectsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectsActions.deleteProject),
      map((action) => action.payload),
      mergeMap((projectModel) => {
        let requests;
        let endpoint;
        if (Array.isArray(projectModel)) {
          requests = projectModel.map((model) => {
            endpoint = this.endpointService.getProjectsSlug(HttpOperation.Delete, model.id);
            return this.http.delete(endpoint, model.id);
          });
        } else {
          endpoint = this.endpointService.getProjectsSlug(HttpOperation.Delete, projectModel.id);
          requests = [this.http.delete(endpoint, projectModel.id)];
        }

        return forkJoin(requests).pipe(
          map(() => {
            return fromProjectsActions.projectDeleted({ payload: projectModel });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Projects',
              fromProjectsActions.deleteProject,
              fromProjectsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
