export interface Position {
  x: number;
  y: number;
}

export class PositionHelper {
  public static compare(
    first: Position,
    second: Position,
    toleranceX = 0,
    toleranceY = 0,
  ): boolean {
    const deltaX = Math.abs(first.x - second.x);
    const deltaY = Math.abs(first.y - second.y);

    return deltaX <= toleranceX && deltaY <= toleranceY;
  }
}
