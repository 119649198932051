import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { TimeBlocksEffects } from './time-blocks/time-blocks.effects';
import { ClientsEffects } from './clients/clients.effects';
import { clientsReducer } from './clients/clients.reducer';
import { TasksEffects } from './projects/tasks/tasks.effects';
import { tasksReducer } from './projects/tasks/tasks.reducer';
import { UsersEffects } from './users/users.effects';
import { usersReducer } from './users/users.reducer';
import { navigationReducer } from './navigation/navigation.reducers';
import { timeBlocksReducer } from './time-blocks/time-blocks.reducer';
import { AppConfigEffects } from './app-config/app-config.effects';
import { appConfigReducer } from './app-config/app-config.reducer';
import { httpErrorReducer } from './http-error/http-error.reducer';
import { ColorsEffects } from './colors/colors.effects';
import { colorsReducer } from './colors/colors.reducer';
import { AbsenceReasonsEffects } from './settings/absence-reasons/absence-reasons.effects';
import { absenceReasonsReducer } from './settings/absence-reasons/absence-reasons.reducer';
import { HolidayTemplatesEffects } from './settings/holiday-templates/holiday-templates.effects';
import { holidayTemplatesReducer } from './settings/holiday-templates/holiday-templates.reducer';
import { projectsReducer } from './projects/projects.reducer';
import { ProjectsEffects } from './projects/projects.effects';
import { StatusEffects } from './projects/status/status.effects';
import { statusReducer } from './projects/status/status.reducer';
import { TimerEffects } from './time-blocks/timer/timer.effects';

export const effects = [
  ProjectsEffects,
  StatusEffects,
  ClientsEffects,
  TasksEffects,
  TimeBlocksEffects,
  UsersEffects,
  ColorsEffects,
  AppConfigEffects,
  AbsenceReasonsEffects,
  HolidayTemplatesEffects,
  TimerEffects,
];

export const reducers: ActionReducerMap<unknown> = {
  clients: clientsReducer,
  projects: projectsReducer,
  tasks: tasksReducer,
  status: statusReducer,
  users: usersReducer,
  navigation: navigationReducer,
  timeBlocks: timeBlocksReducer,
  colors: colorsReducer,
  httpError: httpErrorReducer,
  appConfig: appConfigReducer,
  absenceReasons: absenceReasonsReducer,
  holidayTemplates: holidayTemplatesReducer,
};

export const metaReducers: MetaReducer[] = [];

export interface AppState {}

export const initialState: AppState = {};
