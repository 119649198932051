import { Injectable } from '@angular/core';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { FormGroup } from '@angular/forms';
import {
  TimeBlockContentType,
  TimeBlockUserOperation,
} from '../../../../../shared/data-types/time-block-types';
import { TimeBlockHttpService } from '../../../../../core/state/time-blocks/time-block-http.service';
import { ShiveDialogService } from '../../../../../core/services/controls/shive-dialog.service';
import { TimeBlockComponentHandlerService } from '../generation/time-block-component-handler.service';

@Injectable()
export class DialogHttpRequestHandlerService {
  constructor(
    private readonly timeBlockHttpService: TimeBlockHttpService,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  public init(
    form: FormGroup,
    timeBlockContentType: TimeBlockContentType,
    timeBlockUserOperation: TimeBlockUserOperation,
  ): void {
    if (!form.valid) {
      form.markAllAsTouched();
      return;
    }

    if (!timeBlockUserOperation.timeBlockItem) {
      throw new Error(`Invalid time block item: ${timeBlockUserOperation.timeBlockItem}`);
    }

    const timeBlock = timeBlockUserOperation.timeBlockItem;
    timeBlock.timeBlockContentType = timeBlockContentType;

    if (timeBlockUserOperation.operation === UserOperation.Create) {
      timeBlock.timeBlockModel = TimeBlockComponentHandlerService.hydrateTimeBlockModel(
        timeBlock,
        form.controls,
      );
      this.timeBlockHttpService.postBlock(timeBlock);
    } else if (timeBlockUserOperation.operation === UserOperation.Update) {
      timeBlock.timeBlockModel = TimeBlockComponentHandlerService.hydrateTimeBlockModel(
        timeBlock,
        form.controls,
      );
      this.timeBlockHttpService.putBlock(timeBlock);
    } else if (timeBlockUserOperation.operation === UserOperation.Delete) {
      this.timeBlockHttpService.deleteBlock(timeBlockUserOperation.timeBlockItem);
    }
    this.shiveDialogService.close(timeBlockUserOperation.operation);
  }
}
