import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginModel } from '../../core/models/auth/login.model';
import { RegisterModel } from '../../core/models/auth/register.model';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('password') public textbox: TextBoxComponent;
  @Output() readonly formSubmitted$ = new EventEmitter<LoginModel | RegisterModel>();
  public loginForm: UntypedFormGroup;
  public errorMsg = null;
  public requestSent = false;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userLogin: ['', Validators.required],
      password: ['', [Validators.required]],
      rememberMe: '',
    });
  }

  ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = 'password';
  }

  public onSubmit(): void {
    this.loginForm.markAllAsTouched();

    if (!this.loginForm.valid) {
      return;
    }

    const loginUser = new LoginModel();
    loginUser.username = this.loginForm.controls.userLogin.value;
    loginUser.password = this.loginForm.controls.password.value;
    this.formSubmitted$.emit(loginUser);
  }
}
