<div
  class="content-wrapper k-d-flex k-pl-3 k-pr-3 k-overflow-hidden k-pos-relative noselect tb"
  appEmptyCalendarSpaceInteraction
  #timeBlockBody
>
  <div
    class="event-time k-mt-2 tb"
    *ngIf="
      !model.isFullday &&
      (calendarViewMode === CalendarView.DayGrid || calendarViewMode === CalendarView.WeekGrid)
    "
  >
    <div class="event-duration k-align-items-center k-justify-content-between k-w-full tb">
      <div class="start-and-end">
        <span class="value tb">{{ model.start | date: "HH:mm" }}</span>
        <span>&nbsp;—&nbsp;</span>
        <span class="value pr-1 tb">{{ model.end | endOfDay | date: "HH:mm" }}</span>
      </div>
      <div class="duration k-align-items-center k-d-flex">
        <fa-icon [icon]="faClock" [size]="'xs'" class="k-mt-0.5"></fa-icon>
        <span class="k-ml-1">{{ model | timeBlockTimeDifference }}</span>
      </div>
    </div>
  </div>

  <div class="text tb" [ngClass]="model.isFullday ? '' : 'pt-2 pb-3'">
    <div
      [style.background-color]="model | timeBlockBGColor"
      *ngIf="!model.isFullday && calendarViewMode === CalendarView.MonthGrid"
      class="week-view-bullet-point"
    ></div>
    <h4 class="title k-h-full tb" [style.background-color]="model | timeBlockBGColor">
      Name des Tasks {{ model.id }}
    </h4>
    <div
      class="description k-h-full tb"
      *ngIf="
        !model.isFullday &&
        (calendarViewMode === CalendarView.DayGrid || calendarViewMode === CalendarView.WeekGrid)
      "
    >
      Cur: {{ model.id }} <br />
    </div>
  </div>
</div>
