import { CalendarConfigModel } from './calendar-config.model';
import { Expose, Type } from 'class-transformer';
import { ProjectConfigModel } from './project-config.model';
import { UiConfigModel } from './ui-config.model';

export class AppConfigModel {
  id: number = 0; // Add id to satisfy ngrx/entity.

  @Type(() => CalendarConfigModel)
  @Expose({ name: 'calendarOptions' })
  public calendarConfigModel: CalendarConfigModel = null;

  @Type(() => ProjectConfigModel)
  @Expose({ name: 'projectOptions' })
  public projectConfigModel: ProjectConfigModel = null;

  @Type(() => UiConfigModel)
  @Expose({ name: 'uiOptions' })
  public uiConfigModel: ProjectConfigModel = null;
}
