@if (!requestSent) {
  <div id="auth-wrapper" class="k-flex-1">
    <div id="form-wrapper" class="k-d-flex k-flex-column">
      <div id="form-auth-header" class="k-d-flex k-justify-content-between k-align-items-center">
        <div id="header-title">
          <h2 class="k-text-white k-m-0">{{ isLoginMode ? "Einloggen" : "Registrieren" }}</h2>
        </div>
        <div id="header-logo"></div>
      </div>
      <div id="form-auth-content" class="k-bg-white">
        @if (isLoginMode) {
          <app-login (formSubmitted$)="onSubmit($event)"></app-login>
        }
        @if (!isLoginMode) {
          <app-register (formSubmitted$)="onSubmit($event)"></app-register>
        }
      </div>
    </div>
  </div>
}
