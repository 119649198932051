import { UrlSegmentDelimiter } from './css-constants';
import { TimeBlockContentType } from '../../shared/data-types/time-block-types';

export const TimeBlocksSlug = 'timeblocks';
export const ClientsSlug = 'clients';
export const ProjectsSlug = 'projects';
export const StatusSlug = 'status';
export const TasksSlug = 'tasks';
export const UsersSlug = 'users';
export const ColorsSlug = 'colors';
export const AbsenceReasonsSlug = 'absence-reasons';
export const HolidayTemplatesSlug = 'holiday-templates';
export const TimerSlug = 'timer';

// Auth
export const LoginUserSlug = `accounts${UrlSegmentDelimiter}login`;
export const LoggedInUserSlug = `accounts${UrlSegmentDelimiter}current`;
export const RegisterUserSlug = `accounts${UrlSegmentDelimiter}register`;
export const LogoutUserSlug = `accounts${UrlSegmentDelimiter}logout`;
export const CSRFSlug = 'csrf';

// Timeblock Querystring Params
export const TimeBlockQueryStrings = new Map<TimeBlockContentType, string>([
  [TimeBlockContentType.Project, 'timeblock_project_type'],
  [TimeBlockContentType.Absence, 'timeblock_absence_type'],
  [TimeBlockContentType.Appointment, 'timeblock_appointment_type'],
]);
