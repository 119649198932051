<div class="wrapper k-pos-relative k-h-full">
  <aside
    class="sidebar sidebar-vertical desktop-expand fixed-start k-d-flex k-h-full"
    (transitionend)="translationFinished($event)"
    #aside
  >
    <app-sidebar
      [events]="sidebarService.sidebarEvents$"
      [mainPanel]="mainPanel"
      [aside]="aside"
    ></app-sidebar>
  </aside>
  <main
    class="main-panel k-flex-grow k-d-flex"
    #mainPanel
    (transitionend)="mainPanelTranslationFinished($event)"
  >
    @if (linkbarService.linksSet$.getValue().length > 0) {
      <app-linkbar />
    }
    <div class="main-content k-flex-grow" #mainContent>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
