import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'date-fns';

@Pipe({
  name: 'hourAndMinute',
})
export class HourAndMinutePipe implements PipeTransform {
  transform(duration: Duration): string {
    if (!duration) {
      return 'Ungültig!';
    }

    return `${duration.hours}h ${duration.minutes}min`;
  }
}
