import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { NotificationComponent } from './components/notification/notification.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { MenusModule } from '@progress/kendo-angular-menu';
// Load the required calendar data for the de locale
import '@progress/kendo-angular-intl/locales/de/calendar';
import { ShiveContextMenuComponent } from './components/context/shive-context-menu/shive-context-menu.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ShiveDropdownlistComponent } from './components/form-components/shive-dropdownlist/shive-dropdownlist.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShiveGridComponent } from './components/form-components/shive-grid/shive-grid.component';
import { PendingChangesDialogComponent } from './components/dialogs/pending-changes-dialog/pending-changes-dialog.component';
import { SharedMemberTemplateComponent } from './components/member-template/shared-member-template.component';
import { CoreModule } from '../core/core.module';
import { ContextMenuAnchorComponent } from './components/context/context-menu-anchor/context-menu-anchor.component';
import { HistoryTimeLineComponent } from './components/history-time-line/history-time-line.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    NotificationComponent,
    ShiveContextMenuComponent,
    ContextMenuAnchorComponent,
    ShiveDropdownlistComponent,
    ShiveGridComponent,
    PendingChangesDialogComponent,
    SharedMemberTemplateComponent,
    HistoryTimeLineComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MenusModule,
    PopupModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    ChartsModule,
    DropDownsModule,
    LabelModule,
    InputsModule,
    FontAwesomeModule,
    ButtonModule,
    CoreModule,
  ],
  exports: [
    NotFoundComponent,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    InputsModule,
    NumericTextBoxModule,
    LabelModule,
    ButtonModule,
    DropDownsModule,
    DialogModule,
    NotificationModule,
    DateInputsModule,
    PopupModule,
    ChartsModule,
    GridModule,
    IconsModule,
    ShiveContextMenuComponent,
    ShiveDropdownlistComponent,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NotificationComponent,
    ShiveGridComponent,
    SharedMemberTemplateComponent,
    ContextMenuAnchorComponent,
    HistoryTimeLineComponent,
    FontAwesomeModule,
  ],
})
export class SharedModule {}
