import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorService } from '../../services/http-error.service';
import * as fromTimeBlockActions from './time-blocks.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EndpointService } from '../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../enums/http-operation';
import * as fromTasksActions from '../projects/tasks/tasks.actions';
import { TimeBlockHttpTransformationService } from '../../../features/calendar/components/time-block/http/time-block-http-transformation.service';
import { TimeBlockContentType } from '../../../shared/data-types/time-block-types';
import { TimeBlockQueryStrings } from '../../data-repository/api-parts-querystrings';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';

@Injectable()
export class TimeBlocksEffects {
  fetchTimeBlocks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTimeBlockActions.fetchTimeBlockList),
      map((params) => params.payload),
      switchMap((data) => {
        const startStr = DateTimeHelper.dateToString(data.startDate);
        const endStr = DateTimeHelper.dateToString(data.endDate);

        let params = new HttpParams();
        params = params.append('start_date', startStr);
        params = params.append('end_date', endStr);
        params = params.append('user_id', data.selectedUserIds[0]);

        const endpointTimeBlocks = this.endpointService.getTimeBlockSlug(
          HttpOperation.Get,
          TimeBlockContentType.Project,
        );

        return this.http
          .get<{ fullday_timeblocks; timeblocks }>(endpointTimeBlocks, { params })
          .pipe(
            map((response) => {
              const projectTimeBlocksFullday =
                this.timeBlockHttpTransformationService.toTimeBlockItems(
                  response.fullday_timeblocks.timeblock_project_type,
                  TimeBlockContentType.Project,
                  HttpOperation.Get,
                );
              const projectInnerdayTimeBlocks =
                this.timeBlockHttpTransformationService.toTimeBlockItems(
                  response.timeblocks.timeblock_project_type,
                  TimeBlockContentType.Project,
                  HttpOperation.Get,
                );
              const absenceTimeBlocksFullday =
                this.timeBlockHttpTransformationService.toTimeBlockItems(
                  response.fullday_timeblocks.timeblock_absence_type,
                  TimeBlockContentType.Absence,
                  HttpOperation.Get,
                );
              const absenceInnerdayTimeBlocks =
                this.timeBlockHttpTransformationService.toTimeBlockItems(
                  response.timeblocks.timeblock_absence_type,
                  TimeBlockContentType.Absence,
                  HttpOperation.Get,
                );

              return fromTimeBlockActions.setInitialTimeBlocks({
                payload: [
                  ...projectTimeBlocksFullday,
                  ...projectInnerdayTimeBlocks,
                  ...absenceTimeBlocksFullday,
                  ...absenceInnerdayTimeBlocks,
                ],
                storeInHistory: true,
              });
            }),
            catchError((error) => {
              return this.httpErrorService.handleError(
                'Time blocks',
                fromTimeBlockActions.fetchTimeBlockList,
                fromTimeBlockActions,
                error,
              );
            }),
          );
      }),
    );
  });

  addTimeBlock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTimeBlockActions.addTimeBlock),
      map((action) => action.payload),
      mergeMap((timeBlock) => {
        const endpoint = this.endpointService.getTimeBlockSlug(
          HttpOperation.Post,
          timeBlock.timeBlockContentType,
        );
        const timeBlockModelHttp =
          this.timeBlockHttpTransformationService.toTimeBlockHTTPPost(timeBlock);
        let params = new HttpParams();
        params = params.append('type', TimeBlockQueryStrings.get(timeBlock.timeBlockContentType));

        return this.http.post<unknown>(endpoint, timeBlockModelHttp, { params }).pipe(
          map((response) => {
            const modifiedResponse =
              this.timeBlockHttpTransformationService.toTimeBlockModifiedResponse(
                response,
                timeBlock.timeBlockContentType,
                HttpOperation.Post,
              );
            return fromTimeBlockActions.timeBlockAdded({
              payload: {
                modifiedTimeBlockItems: modifiedResponse.modifiedTimeBlockItems,
                responseCode: modifiedResponse.responseCode,
              },
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Time blocks',
              fromTimeBlockActions.addTimeBlock,
              fromTimeBlockActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateTimeBlock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTimeBlockActions.updateTimeBlock),
      map((action) => action.payload),
      mergeMap((timeBlock) => {
        const endpoint = this.endpointService.getTimeBlockSlug(
          HttpOperation.Put,
          timeBlock.timeBlockModel.id,
        );
        const timeBlockModelHttp =
          this.timeBlockHttpTransformationService.toTimeBlockHTTPPut(timeBlock);

        return this.http.put<unknown>(endpoint, timeBlockModelHttp).pipe(
          map((response) => {
            const modifiedResponse =
              this.timeBlockHttpTransformationService.toTimeBlockModifiedResponse(
                response,
                timeBlock.timeBlockContentType,
                HttpOperation.Put,
              );

            return fromTimeBlockActions.timeBlockUpdated({
              payload: {
                modifiedTimeBlockItems: modifiedResponse.modifiedTimeBlockItems,
                responseCode: modifiedResponse.responseCode,
              },
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Time blocks',
              fromTimeBlockActions.updateTimeBlock,
              fromTimeBlockActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteTimeBlock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTimeBlockActions.deleteTimeBlock),
      map((action) => action.payload),
      mergeMap((timeBlockId) => {
        const endpoint = this.endpointService.getTimeBlockSlug(HttpOperation.Delete, timeBlockId);
        return this.http.delete<unknown>(endpoint).pipe(
          map(() => {
            return fromTimeBlockActions.timeBlockDeleted({
              payload: {
                deletedTimeBlockId: timeBlockId,
                responseCode: 200,
              },
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'timeBlocks',
              fromTimeBlockActions.deleteTimeBlock,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly timeBlockHttpTransformationService: TimeBlockHttpTransformationService,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
