import { FormGroup, ValidationErrors } from '@angular/forms';
import { DateTimeHelper } from '../../features/calendar/util/date-time-helper';
import { Injectable, inject } from '@angular/core';
import { CalendarService } from 'src/app/features/calendar/services/calendar.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFormValidators {
  private readonly calendarService = inject(CalendarService);

  public endAfterStart(form: FormGroup): ValidationErrors | null {
    const controls = form.controls;
    const startDate = DateTimeHelper.mergeDateAndTime(
      controls.dateFrom.value,
      controls.timeFrom.value,
    );
    const endDate = DateTimeHelper.mergeDateAndTime(controls.dateTo.value, controls.timeTo.value);

    if (DateTimeHelper.isBefore(endDate, startDate)) {
      controls.dateTo.setErrors({ invalidEndDate: true });
      return { invalidEndDate: true };
    }

    controls.dateTo.setErrors(null);
    return null;
  }

  public timeBlockDurationTooSmall(form: FormGroup): ValidationErrors | null {
    const controls = form.controls;
    // const calendarService = inject(CalendarService);
    const minimumTimeBlockDuration =
      this.calendarService.model.calendarProperties.minimumTimeBlockDuration;
    const startDate = DateTimeHelper.mergeDateAndTime(
      controls.dateFrom.value,
      controls.timeFrom.value,
    );
    const endDate = DateTimeHelper.mergeDateAndTime(controls.dateTo.value, controls.timeTo.value);
    const differenceInMinutes = DateTimeHelper.differenceInMinutes(startDate, endDate);
    if (differenceInMinutes < minimumTimeBlockDuration) {
      controls.dateTo.setErrors({ invalidEndDate: true });
      return { timeEntryTooShort: true };
    }

    controls.dateTo.setErrors(null);
    return null;
  }
}
