import { createReducer, on } from '@ngrx/store';
import { NavigationModel } from '../../models/navigation/navigation.model';
import { setNavigation } from './navigation.actions';

export const initialState: NavigationModel = new NavigationModel();
export const navigationReducer = createReducer(
  initialState,
  on(setNavigation, (state, { payload }): Readonly<NavigationModel> => {
    const updatedNavigationModel = new NavigationModel();
    Object.assign(updatedNavigationModel, payload);
    return updatedNavigationModel;
  }),
);
