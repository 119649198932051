import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidaySettingsComponent } from './time-off/holiday/holiday-settings.component';
import { CoreModule } from '../../core/core.module';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '../../shared/shared.module';
import { HolidaySettingsHolidayGridComponent } from './time-off/holiday/holiday-settings-holiday-grid/holiday-settings-holiday-grid.component';
import { HolidaySettingsMemberGridComponent } from './time-off/holiday/holiday-settings-member-grid/holiday-settings-member-grid.component';
import { HolidayTemplateDialogComponent } from './time-off/holiday/dialogs/holiday-template-dialog/holiday-template-dialog.component';
import { AddMemberDialogComponent } from '../../shared/components/dialogs/add-member-dialog/add-member-dialog.component';
import { HolidaySettingsHolidayDialogComponent } from './time-off/holiday/dialogs/holiday-settings-holiday-dialog/holiday-settings-holiday-dialog.component';
import { TimeOffComponent } from './time-off/time-off.component';
import { AbsenceReasonSettingsComponent } from './time-off/absence-reason-settings/absence-reason-settings.component';
import { HolidaySettingsStore } from './time-off/state-and-data-handling/holiday-settings.store';
import { AbsenceReasonSettingsDialogComponent } from './time-off/absence-reason-settings/dialog/absence-type-settings-dialog/absence-reason-settings-dialog.component';
import { AbsenceReasonsStore } from './time-off/state-and-data-handling/absence-reasons.store';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule],
  declarations: [
    HolidaySettingsComponent,
    SettingsComponent,
    HolidaySettingsHolidayGridComponent,
    HolidaySettingsMemberGridComponent,
    HolidayTemplateDialogComponent,
    AddMemberDialogComponent,
    HolidaySettingsHolidayDialogComponent,
    AbsenceReasonSettingsComponent,
    TimeOffComponent,
    AbsenceReasonSettingsDialogComponent,
  ],
  providers: [HolidaySettingsStore, AbsenceReasonsStore],
})
export class SettingsModule {}
