<div class="form-wrapper">
  <form class="k-form" [formGroup]="absenceForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="reason" text="Grund"></kendo-label>
      <kendo-dropdownlist
        [data]="absenceReasonOptions"
        textField="text"
        valueField="value"
        formControlName="reason"
        (valueChange)="absenceReasonChanged($event)"
        #reason
      >
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <ng-container>
            <span>{{ dataItem?.text }}</span>
            @if (dataItem?.value === 3) {
              <span #popupAnchor>
                <a shivePopup [popupAnchor]="popupAnchor" [content]="popupContent">
                  <fa-icon class="k-ml-2.5 turquoise" [icon]="faCircleInfo" size="lg"></fa-icon>
                </a>
              </span>
            }
          </ng-container>
        </ng-template>
      </kendo-dropdownlist>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>

    <app-dialog-date-and-time-input
      [timeBlock]="selectedTimeBlock()"
      [form]="absenceForm"
      [fulldayChecked]="true"
    ></app-dialog-date-and-time-input>
  </form>
</div>

<ng-container [ngTemplateOutlet]="footer()"></ng-container>
