import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '../../services/http-error.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAppDataActions from './app-config.actions';
import { AppConfigModel } from '../../models/app-config/app-config.model';
import { plainToInstance } from 'class-transformer';
import { configPath } from '../../../../assets/config/config-constants';

@Injectable()
export class AppConfigEffects {
  fetchAppConfigData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAppDataActions.fetchAppConfigData),
      switchMap(() => {
        return this.http.get<unknown>(configPath).pipe(
          map((json: unknown) => {
            const appConfigData = plainToInstance(AppConfigModel, json);
            return fromAppDataActions.setAppConfigData({ payload: appConfigData });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'App data',
              fromAppDataActions.fetchAppConfigData,
              fromAppDataActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
