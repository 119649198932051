import { FulldayDuration } from '../../../shared/data-types/calendar-types';

export class CalendarConfigModel {
  public hoursOfDay = -1;
  public defaultCalendarView = -1;
  public weekStartsOn = -1;
  public timeFormat = '';
  public timeBlockDraggingDuration = -1;
  public minimumTimeBlockDuration = -1;
  public workTimeStart = '';
  public workTimeEnd = '';
  public fulldayDuration: FulldayDuration = 0;
}
