import {
  Directive,
  HostListener,
  Renderer2,
  TemplateRef,
  inject,
  input,
  output,
} from '@angular/core';
import { ShivePopupService } from '../services/controls/shive-popup.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[shivePopup]',
  standalone: true,
})
export class ShivePopupDirective {
  popupAnchor = input.required<HTMLElement>();
  componentParams = input<any>();

  // eslint-disable-next-line @typescript-eslint/ban-types
  content = input.required<Function | TemplateRef<any>>();
  openOnHover = input<boolean>(false);
  visible = output<boolean>();
  private readonly shivePopupService = inject(ShivePopupService);
  private readonly renderer = inject(Renderer2);

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.shivePopupService.popupRef) {
      this.shivePopupService.popupRef.close();
    }

    this.shivePopupService.open({
      content: this.content(),
      anchor: this.popupAnchor(),
      anchorAlign: { horizontal: 'right', vertical: 'center' },
      popupAlign: { horizontal: 'left', vertical: 'center' },
      margin: { horizontal: 20, vertical: 0 },
      popupClass: 'tooltip context-menu arrow-left px-3 k-opacity-0',
    });

    const componentRef = this.shivePopupService.popupRef.content;

    componentRef.instance.componentParams = this.componentParams();
    this.shivePopupService.dataLoaded$.pipe(take(1)).subscribe(() => {
      const popupEl = this.shivePopupService.popupRef.popup.instance.contentContainer.nativeElement;
      this.renderer.removeClass(popupEl, 'tooltip-invisible');
      this.renderer.removeClass(popupEl, 'k-opacity-0');
      this.renderer.addClass(popupEl, 'tooltip-visible');
    });
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    const popupEl = this.shivePopupService.popupRef.popup.instance.contentContainer.nativeElement;
    popupEl.onanimationend = () => {
      this.shivePopupService.popupRef.close();
    };

    this.renderer.removeClass(popupEl, 'tooltip-visible');
    this.renderer.addClass(popupEl, 'tooltip-invisible');
  }
}
