import { Injectable } from '@angular/core';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { environment } from '../../../../environments/environment';
import {
  CSRFSlug,
  LoggedInUserSlug,
  LoginUserSlug,
  LogoutUserSlug,
  RegisterUserSlug,
} from '../../data-repository/api-parts-querystrings';

@Injectable({
  providedIn: 'root',
})
export class AuthEndpointsService {
  private readonly PREFIX = environment.apiPath + UrlSegmentDelimiter;

  // ////////////############ Login ############////////////
  public getLoginUserSlug(): string {
    return this.PREFIX + LoginUserSlug + UrlSegmentDelimiter;
  }

  // ////////////############ Current ############////////////
  public getLoggedInUserSlug(): string {
    return this.PREFIX + LoggedInUserSlug + UrlSegmentDelimiter;
  }

  // ////////////############ Register ############////////////
  public getRegisterUserSlug(): string {
    return this.PREFIX + RegisterUserSlug + UrlSegmentDelimiter;
  }

  // ////////////############ Logout ############////////////
  public getLogoutSlug(): string {
    return this.PREFIX + LogoutUserSlug + UrlSegmentDelimiter;
  }

  // ////////////############ CSRF ############////////////
  public getCSRFSlug(): string {
    return this.PREFIX + CSRFSlug + UrlSegmentDelimiter;
  }
}
