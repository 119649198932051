<div class="form-wrapper">
  <form class="k-form" [formGroup]="absenceForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="reason" text="Grund"></kendo-label>
      <kendo-dropdownlist
        [data]="absenceReasonOptions"
        textField="text"
        valueField="value"
        formControlName="reason"
        #reason
      >
      </kendo-dropdownlist>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>

    <app-dialog-time-input
      [timeBlock]="selectedTimeBlock"
      [form]="absenceForm"
    ></app-dialog-time-input>
  </form>
</div>

<ng-container [ngTemplateOutlet]="footer"></ng-container>
