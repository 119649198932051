import { Component, Input, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DropdownOption } from '../../../../../shared/data-types/dropdown-option';
import {
  entityToDropdownOption,
  observableToDropdownOptions,
} from '../../../../../shared/functions/dropdown-functions';
import { AbsenceReasonsHttpService } from '../../../../../core/state/settings/absence-reasons/absence-reasons-http.service';
import { ITimeBlockComponentItem } from '../../../components/time-block/time-block-component-items';
import {
  TimeBlockContentType,
  TimeBlockUserOperation,
} from '../../../../../shared/data-types/time-block-types';
import { SubSink } from 'subsink';
import { DialogHttpRequestHandlerService } from '../../../components/time-block/http/dialog-http-request-handler.service';
import { CustomFormValidators } from '../../../../../shared/validators/custom-form.validators';
import { TimeBlockAbsenceModel } from '../../../../../core/models/timeblock/time-block.model';

@Component({
  selector: 'app-time-off-type-dialog',
  templateUrl: './time-off-type-dialog.component.html',
  styleUrls: ['./time-off-type-dialog.component.scss'],
})
export class TimeOffTypeDialogComponent implements OnInit, OnDestroy {
  @Input({ required: true }) selectedTimeBlock: ITimeBlockComponentItem;
  @Input({ required: true }) actionObs$: Observable<TimeBlockUserOperation>;
  @Input({ required: true }) footer!: TemplateRef<any>;
  public absenceForm: FormGroup;
  public absenceReasonOptions: DropdownOption[];
  private readonly subs = new SubSink();
  private readonly customFormValidators = inject(CustomFormValidators);

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly dialogHttpRequestHandlerService: DialogHttpRequestHandlerService,
    private readonly absenceReasonsService: AbsenceReasonsHttpService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.subs.sink = this.actionObs$.subscribe((timeBlockUserOperation) => {
      this.dialogHttpRequestHandlerService.init(
        this.absenceForm,
        TimeBlockContentType.Absence,
        timeBlockUserOperation,
      );
    });

    this.subs.sink = observableToDropdownOptions(
      this.absenceReasonsService.getAbsenceReasons(),
    ).subscribe((options) => {
      this.absenceReasonOptions = options;
      let selectedAbsenceReasonOption = options[0];
      const timeBlockModel = this.selectedTimeBlock.timeBlockModel;
      if (timeBlockModel instanceof TimeBlockAbsenceModel) {
        selectedAbsenceReasonOption = entityToDropdownOption(timeBlockModel.reason);
      }

      this.absenceForm.patchValue({
        reason: selectedAbsenceReasonOption,
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initForm(): void {
    this.absenceForm = this.formBuilder.group(
      {
        reason: [null, Validators.required],
        dateFrom: [this.selectedTimeBlock?.timeBlockModel.start, Validators.required],
        dateTo: [this.selectedTimeBlock?.timeBlockModel.end, Validators.required],
        fullday: [this.selectedTimeBlock?.timeBlockModel.isFullday],
        timeFrom: [this.selectedTimeBlock?.timeBlockModel.start, Validators.required],
        timeTo: [this.selectedTimeBlock?.timeBlockModel.end, Validators.required],
        recurrence: [null],
      },
      {
        validators: this.customFormValidators.endAfterStart.bind(this.customFormValidators),
      },
    );
  }
}
