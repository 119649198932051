import { createAction, props } from '@ngrx/store';
import { ProjectTaskModel } from '../../../models/project/project-task.model';

export const fetchSingleTask = createAction(
  '[Tasks] Fetch Single Task',
  props<{ payload: number }>(),
);
export const setSingleTask = createAction(
  '[Tasks] Set Single Task',
  props<{ payload: ProjectTaskModel }>(),
);

export const fetchTaskList = createAction('[Tasks] Fetch Tasks', props<{ payload: number }>());
export const setTasks = createAction('[Tasks] Set Tasks', props<{ payload: ProjectTaskModel[] }>());

export const addTask = createAction(
  '[Tasks] Add Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);
export const taskAdded = createAction(
  '[Tasks] Added Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);

export const updateTask = createAction(
  '[Tasks] Update Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);
export const taskUpdated = createAction(
  '[Tasks] Updated Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);

export const deleteTask = createAction(
  '[Tasks] Delete Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);
export const taskDeleted = createAction(
  '[Tasks] Deleted Task',
  props<{ payload: ProjectTaskModel | ProjectTaskModel[] }>(),
);

export const httpFail = createAction('[Tasks] Http fail', props<{ payload: string }>());
