<div [formGroup]="form()">
  <!-- Date from and to -->
  <div id="date-group" class="k-d-grid k-align-items-center k-mb-6">
    <kendo-label class="k-w-1/4 k-mr-2.5 !k-mt-0" [for]="dateFrom" text="Datum"> </kendo-label>
    <div class="input-fields k-d-grid k-align-items-center">
      <kendo-formfield orientation="horizontal" class="!k-mt-0 !k-mb-0">
        <kendo-datepicker
          formControlName="dateFrom"
          #dateFrom
          appAfterValueChanged
          (afterValueChanged)="form().markAllAsTouched()"
        ></kendo-datepicker>
      </kendo-formfield>

      <span class="k-text-center k-mt-0 k-ml-5.5 k-mr-5.5">bis</span>

      <kendo-formfield orientation="horizontal" class="k-justify-content-end !k-mt-0 !k-mb-0">
        <kendo-datepicker
          formControlName="dateTo"
          appAfterValueChanged
          (afterValueChanged)="form().markAllAsTouched()"
        ></kendo-datepicker>
      </kendo-formfield>
    </div>
  </div>

  <!-- Time from and to -->
  <div id="time-group" class="k-d-grid k-align-items-center k-mb-6">
    <kendo-label class="k-w-1/4 k-mr-2.5 !k-mt-0" [for]="timeFrom" text="Uhrzeit"> </kendo-label>
    <kendo-formfield orientation="horizontal" class="!k-mt-0 !k-mb-0">
      <kendo-timepicker
        [popupSettings]="{
          appendTo: vcRef,
        }"
        formControlName="timeFrom"
        #timeFrom
        appAfterValueChanged
        (afterValueChanged)="form().markAllAsTouched()"
      >
      </kendo-timepicker>
    </kendo-formfield>

    <span class="k-text-center k-mt-0 k-ml-5.5 k-mr-5.5">bis</span>

    <kendo-formfield orientation="horizontal" class="!k-mt-0 !k-mb-0">
      <kendo-timepicker
        [popupSettings]="{
          appendTo: vcRef,
        }"
        formControlName="timeTo"
        appAfterValueChanged
        (afterValueChanged)="form().markAllAsTouched()"
      >
      </kendo-timepicker>
    </kendo-formfield>

    <div id="full-day-checkbox">
      <input
        type="checkbox"
        #fullday
        (click)="toggleTimeInputFields()"
        formControlName="fullday"
        kendoCheckBox
      />
      <kendo-label class="k-checkbox-label" [for]="fullday" text="Ganzer Arbeitstag"></kendo-label>
    </div>
  </div>
</div>
