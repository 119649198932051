import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamComponent } from './team.component';
import { ListTeamComponent } from './list-team/list-team.component';
import { DetailTeamMemberComponent } from './detail-team-member/detail-team-member.component';

@NgModule({
  declarations: [TeamComponent, ListTeamComponent, DetailTeamMemberComponent],
  imports: [CommonModule],
})
export class TeamModule {}
