import { Component, EventEmitter, Output } from '@angular/core';
import { LoginModel } from '../../core/models/auth/login.model';
import { RegisterModel } from '../../core/models/auth/register.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  @Output() readonly formSubmitted$ = new EventEmitter<LoginModel | RegisterModel>();
}
