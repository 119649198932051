import { createReducer, on } from '@ngrx/store';
import { addHttpError } from './http-error.actions';
import { HttpErrorResponse } from '@angular/common/http';

export const initialState: HttpErrorResponse = null;
export const httpErrorReducer = createReducer(
  initialState,
  on(addHttpError, (state, { payload }): HttpErrorResponse => {
    return payload;
  }),
);
