import { UserModel } from '../../models/user/user.model';
import { createReducer, on } from '@ngrx/store';
import { setSingleUser, setUsers, userAdded, userUpdated } from './users.actions';

export const initialState: readonly UserModel[] = [];
export const usersReducer = createReducer(
  initialState,
  on(setSingleUser, (state, { payload }): readonly UserModel[] => {
    return [...state, payload];
  }),
  on(setUsers, (state, { payload }): readonly UserModel[] => {
    return [...state, ...payload];
  }),
  on(userAdded, (state, { payload }): readonly UserModel[] => {
    return [...state, payload];
  }),
  on(userUpdated, (state, { payload }): readonly UserModel[] => {
    const userModelIndex = state.findIndex((user) => user.id === payload.id);
    const updatedUserModel = new UserModel();

    const users = [...state];
    Object.assign(updatedUserModel, payload);
    users.splice(userModelIndex, 1);
    users.push(updatedUserModel);

    return [...users];
  }),
);
