import { Component } from '@angular/core';

@Component({
  selector: 'app-user-profile-social-security',
  standalone: true,
  imports: [],
  templateUrl: './user-profile-social-security.component.html',
  styleUrl: './user-profile-social-security.component.scss',
})
export class UserProfileSocialSecurityComponent {}
