import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusProjectComponent } from './detail-project/status-project/status-project.component';
import { AttachmentProjectComponent } from './detail-project/attachment-project/attachment-project.component';
import { ActivityLogProjectComponent } from './detail-project/activity-log-project/activity-log-project.component';
import { ProjectBaseFormComponent } from './detail-project/edit-project/manage-project/project-base-form/project-base-form.component';
import { ProjectCalculationFormComponent } from './detail-project/edit-project/manage-project/project-calculation-form/project-calculation-form.component';
import { ListProjectComponent } from './list-project/list-project.component';
import { ManageProjectComponent } from './detail-project/edit-project/manage-project/manage-project.component';
import { ProjectComponent } from './project.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { StatusProjectSummaryComponent } from './detail-project/status-project/status-project-summary/status-project-summary.component';
import { EditProjectComponent } from './detail-project/edit-project/edit-project.component';
import { ProjectMemberGridComponent } from './detail-project/edit-project/manage-project/project-member-grid/project-member-grid.component';
import { ProjectTaskGridComponent } from './detail-project/edit-project/manage-project/project-task-grid/project-task-grid.component';
import { ProjectCalculationFormBillingComponent } from './detail-project/edit-project/manage-project/project-calculation-form/project-calculation-form-billing/project-calculation-form-billing.component';
import { ProjectCalculationFormBudgetComponent } from './detail-project/edit-project/manage-project/project-calculation-form/project-calculation-form-budget/project-calculation-form-budget.component';
import { ProjectDetailDataStore } from './state-and-data-handling/project-detail-data.store';
import { ProjectTaskDialogComponent } from './detail-project/edit-project/dialogs/project-task-dialog/project-task-dialog.component';
import { ProjectCalculationFormResetBudgetComponent } from './detail-project/edit-project/manage-project/project-calculation-form/project-calculation-form-reset-budget/project-calculation-form-reset-budget.component';
import { CreateClientDialogComponent } from './detail-project/edit-project/dialogs/create-client-dialog/create-client-dialog.component';
import { BillingHistoryDialogComponent } from './detail-project/edit-project/dialogs/billing-history-dialog/billing-history-dialog.component';
import { AfterValueChangedDirective } from '../../core/directives/after-value-changed.directive';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule, AfterValueChangedDirective],
  declarations: [
    StatusProjectComponent,
    AttachmentProjectComponent,
    ActivityLogProjectComponent,
    ProjectBaseFormComponent,
    ProjectCalculationFormComponent,
    ListProjectComponent,
    ManageProjectComponent,
    ProjectComponent,
    DetailProjectComponent,
    StatusProjectSummaryComponent,
    EditProjectComponent,
    ProjectMemberGridComponent,
    ProjectTaskGridComponent,
    ProjectCalculationFormBillingComponent,
    ProjectCalculationFormBudgetComponent,
    ProjectTaskDialogComponent,
    ProjectCalculationFormResetBudgetComponent,
    CreateClientDialogComponent,
    BillingHistoryDialogComponent,
  ],
  exports: [ProjectTaskGridComponent],
  providers: [ProjectDetailDataStore],
})
export class ProjectModule {}
