import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromHttpErrorActions from '../state/http-error/http-error.actions';
import { SkipErrorInterceptor } from '../data-repository/css-constants';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.headers.get(SkipErrorInterceptor)) {
          return next.handle(request);
        }

        this.store.dispatch(
          fromHttpErrorActions.addHttpError({
            payload: error,
          }),
        );
        return throwError(() => new Error(error.message));
      }),
    );
  }
}
