import { Component, Input, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { SubSink } from 'subsink';
import {
  DropdownDefaultItem,
  DropdownOption,
} from '../../../../../shared/data-types/dropdown-option';
import { TasksHttpService } from '../../../../../core/state/projects/tasks/tasks-http.service';
import { ITimeBlockComponentItem } from '../../../components/time-block/time-block-component-items';
import {
  entityToDropdownOption,
  observableToDropdownOptions,
} from '../../../../../shared/functions/dropdown-functions';
import {
  TimeBlockContentType,
  TimeBlockType,
  TimeBlockUserOperation,
} from '../../../../../shared/data-types/time-block-types';
import { TimeBlockProjectModel } from '../../../../../core/models/timeblock/time-block.model';
import { DialogHttpRequestHandlerService } from '../../../components/time-block/http/dialog-http-request-handler.service';
import { CustomFormValidators } from '../../../../../shared/validators/custom-form.validators';
import { ProjectsHttpService } from '../../../../../core/state/projects/projects-http.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-project-type-dialog',
  templateUrl: './project-type-dialog.component.html',
  styleUrls: ['./project-type-dialog.component.scss'],
})
export class ProjectTypeDialogComponent implements OnInit, OnDestroy {
  @Input() selectedTimeBlock: ITimeBlockComponentItem;
  @Input() actionObs$: Observable<TimeBlockUserOperation>;
  @Input({ required: true }) footer!: TemplateRef<any>;
  public projectTypeForm: FormGroup;
  public projects$: Observable<DropdownOption[]>;

  public tasks: DropdownOption[];
  private selectedProject: DropdownOption = null;
  private selectedTask: DropdownOption = null;
  private readonly subs = new SubSink();
  private readonly customFormValidators = inject(CustomFormValidators);

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly projectsListHttpService: ProjectsHttpService,
    private readonly dialogHttpRequestHandlerService: DialogHttpRequestHandlerService,
    private readonly taskService: TasksHttpService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.subs.sink = this.actionObs$.subscribe((timeBlockUserOperation) => {
      this.dialogHttpRequestHandlerService.init(
        this.projectTypeForm,
        TimeBlockContentType.Project,
        timeBlockUserOperation,
      );
    });

    this.projects$ = observableToDropdownOptions(
      this.projectsListHttpService.getProjects(),
    ).addUnassignedOption();

    const obs$ = this.selectedProject
      ? observableToDropdownOptions(this.taskService.getTasks(+this.selectedProject.value))
      : of([DropdownDefaultItem as DropdownOption]);

    obs$.pipe(take(1)).subscribe((options) => {
      this.tasks = options;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public projectChanged(selProject: DropdownOption): void {
    const projectId = selProject.value !== null ? +selProject.value : null;
    const obs$ = projectId
      ? observableToDropdownOptions(this.taskService.getTasks(projectId))
      : of([DropdownDefaultItem as DropdownOption]);

    obs$.pipe(take(1)).subscribe((options) => {
      this.tasks = options;
      const selectedOption = options.length > 0 ? options[0] : DropdownDefaultItem;
      this.projectTypeForm.controls.task.setValue(selectedOption);
    });
  }

  private initForm(): void {
    const timeBlockModel = this.selectedTimeBlock.timeBlockModel as TimeBlockProjectModel;

    if (timeBlockModel.type === TimeBlockType.ExistingBlock) {
      this.selectedProject = entityToDropdownOption(timeBlockModel.project);
      this.selectedTask = entityToDropdownOption(timeBlockModel.task);
    }

    const timeBlockProjectContentModel = this.selectedTimeBlock
      ?.timeBlockModel as TimeBlockProjectModel;
    this.projectTypeForm = this.formBuilder.group(
      {
        project: [this.selectedProject],
        task: [this.selectedTask],
        description: [timeBlockProjectContentModel?.text],
        dateFrom: [this.selectedTimeBlock?.timeBlockModel.start],
        dateTo: [this.selectedTimeBlock?.timeBlockModel.end],
        fullday: [this.selectedTimeBlock?.timeBlockModel.isFullday],
        timeFrom: [this.selectedTimeBlock?.timeBlockModel.start],
        timeTo: [this.selectedTimeBlock?.timeBlockModel.end],
        recurrence: [null],
      },
      {
        validators: [
          this.customFormValidators.endAfterStart.bind(this.customFormValidators),
          this.customFormValidators.timeBlockDurationTooSmall.bind(this.customFormValidators),
        ],
      },
    );
  }
}
