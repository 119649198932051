import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorService } from '../../services/http-error.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { EndpointService } from '../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../enums/http-operation';
import { UserModel } from '../../models/user/user.model';
import * as fromUserActions from '../users/users.actions';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsersEffects {
  fetchUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.fetchUserList),
      switchMap(() => {
        const endpoint = this.endpointService.getUsersSlug(HttpOperation.Get);

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown[]) => {
            const users = plainToInstance(UserModel, json);
            return fromUserActions.setUsers({ payload: users });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Users',
              fromUserActions.fetchUserList,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchSingleUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.fetchSingleUser),
      map((data) => data.payload),
      switchMap((userId) => {
        const endpoint = this.endpointService.getUsersSlug(HttpOperation.Get) + userId;

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const user = plainToInstance(UserModel, json);
            return fromUserActions.setSingleUser({ payload: user });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Users',
              fromUserActions.fetchSingleUser,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.addUser),
      map((action) => action.payload),
      mergeMap((userModel) => {
        const endpoint = this.endpointService.getUsersSlug(HttpOperation.Post);
        return this.http.post<unknown>(endpoint, userModel).pipe(
          map((user: UserModel) => {
            return fromUserActions.userAdded({ payload: user });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Users',
              fromUserActions.addUser,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.updateUser),
      map((action) => action.payload),
      mergeMap((userModel) => {
        const endpoint = this.endpointService.getUsersSlug(HttpOperation.Put, userModel.id);
        return this.http.put<unknown>(endpoint, userModel).pipe(
          map((user: UserModel) => {
            return fromUserActions.userUpdated({ payload: user });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Users',
              fromUserActions.updateUser,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.deleteUser),
      map((action) => action.payload),
      mergeMap((userId) => {
        const endpoint = this.endpointService.getUsersSlug(HttpOperation.Delete, userId);
        return this.http.delete<unknown>(endpoint).pipe(
          map(() => {
            return fromUserActions.userDeleted();
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Users',
              fromUserActions.deleteUser,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
