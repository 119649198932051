import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '../../services/http-error.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { EndpointService } from '../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../enums/http-operation';
import { ColorModel } from '../../models/color/color.model';
import * as fromColorsActions from '../colors/colors.actions';

@Injectable()
export class ColorsEffects {
  fetchColors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromColorsActions.fetchColorList),
      switchMap(() => {
        const endpoint = this.endpointService.getColorsSlug(HttpOperation.Get);
        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown[]) => {
            const colors = plainToInstance(ColorModel, json);
            return fromColorsActions.setColors({ payload: colors });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Colors',
              fromColorsActions.fetchColorList,
              fromColorsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchSingleColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromColorsActions.fetchSingleColor),
      map((data) => data.payload),
      switchMap((colorId) => {
        const endpoint = this.endpointService.getColorsSlug(HttpOperation.Get, colorId);

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const color = plainToInstance(ColorModel, json);
            return fromColorsActions.setSingleColor({ payload: color });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Colors',
              fromColorsActions.fetchSingleColor,
              fromColorsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromColorsActions.addColor),
      map((action) => action.payload),
      mergeMap((colorModel) => {
        const endpoint = this.endpointService.getColorsSlug(HttpOperation.Post);
        return this.http.post<{ id; value }>(endpoint, colorModel).pipe(
          map((colors) => {
            return fromColorsActions.colorAdded({ payload: colors });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Colors',
              fromColorsActions.addColor,
              fromColorsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromColorsActions.updateColor),
      map((action) => action.payload),
      mergeMap((colorModel) => {
        const endpoint = this.endpointService.getColorsSlug(HttpOperation.Put, colorModel.id);
        return this.http.put<ColorModel>(endpoint, colorModel).pipe(
          map((color) => {
            return fromColorsActions.colorUpdated({ payload: color });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Colors',
              fromColorsActions.updateColor,
              fromColorsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteColor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromColorsActions.deleteColor),
      map((action) => action.payload),
      mergeMap((colorModel) => {
        const endpoint = this.endpointService.getColorsSlug(HttpOperation.Delete, colorModel.id);
        return this.http.delete<{ id; value }>(endpoint).pipe(
          map((deletedColor) => {
            return fromColorsActions.colorDeleted({ payload: deletedColor });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Colors',
              fromColorsActions.deleteColor,
              fromColorsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
