<nav class="navbar secondary navbar-transparent k-display-block k-pl-4 k-pr-4">
  <div class="navigation">
    <ul class="navbar-nav k-flex-row k-justify-content-around k-align-items-center">
      <li class="nav-item">
        <a class="nav-link text-decoration-none user-select-none">
          <span class="icon notifications"></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link pe-0" (click)="openContextMenu()" #contextMenu>
          <img ngSrc="assets/img/mock/avatar.png" width="40" height="40" alt="avatar" />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-decoration-none user-select-none"
          [routerLink]="'/' + ShiveRoutes.SettingsNonworkingTime"
        >
          <span class="icon settings"></span>
        </a>
      </li>
    </ul>
  </div>
</nav>

<ng-template #contextMenuHeader>
  <div id="profile-menu-header" class="k-d-flex k-flex-col k-align-items-center k-py-4">
    <img ngSrc="assets/img/mock/avatar-large.png" width="50" height="50" alt="avatar" />
    <div class="fullname k-mt-2 k-font-weight-semibold">
      <span>Florian</span>&nbsp;
      <span>Ennemoser</span>
    </div>

    <div class="email k-font-weight-extra-light">
      <span>florian.ennemoser&#64;xemedo.com</span>
    </div>
  </div>
</ng-template>

<ng-template #contextMenuFooter>
  <div id="profile-menu-footer" class="k-d-flex k-flex-col k-align-items-center">
    <div class="context-item k-d-flex k-w-full" role="button">
      <span class="icon-button k-d-flex k-align-items-center">
        <fa-icon class="k-mr-3" [icon]="faRightFromBracket"></fa-icon>
      </span>
      <span>Abmelden</span>
    </div>
  </div>
</ng-template>
