import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { TouchAndMouseHandlerService } from '../../services/touch-and-mouse-handler.service';
import { ShiveDialogService } from '../../services/controls/shive-dialog.service';
import { SidebarService } from '../../services/ui/sidebar.service';
import { LinkbarService } from '../template-parts/linkbar/linkbar.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  @ViewChild('mainContent') public mainContent: ElementRef<HTMLDivElement>;
  @ViewChild('mainPanel') public mainPanel: ElementRef<HTMLDivElement>;
  @ViewChild('aside') public aside: ElementRef<HTMLElement>;

  constructor(
    public sidebarService: SidebarService,
    public readonly linkbarService: LinkbarService,
    private readonly renderer: Renderer2,
    private readonly navigationService: NavigationService, // Don't remove
    private readonly shiveDialogService: ShiveDialogService, // Don't remove
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
  ) {}

  @HostListener('window:mousemove', ['$event'])
  public onMouseMove(e: MouseEvent): void {
    this.touchAndMouseHandlerService.globalMouseMoved$.next(e);
    this.touchAndMouseHandlerService.currentMouseViewportPos = {
      x: e.clientX,
      y: e.clientY,
    };
  }

  @HostListener('window:mouseup', ['$event'])
  public onMouseUp(e: MouseEvent): void {
    this.touchAndMouseHandlerService.globalMouseReleased$.next(e);
  }

  public translationFinished($event: TransitionEvent): void {
    // This callback function is called multiple times after translation has completed, so guard it.
    if (this.sidebarService.translationInProgress) {
      this.sidebarService.sidebarEvents$.next($event);
      this.sidebarService.translationInProgress = false;
    }
  }

  public mainPanelTranslationFinished($event: TransitionEvent): void {
    const containsExpand = this.mainPanel.nativeElement.classList.contains(
      'scale-content-area-expand',
    );
    const containsCompress = this.mainPanel.nativeElement.classList.contains(
      'scale-content-area-compress',
    );

    if ($event.propertyName === 'transform' && (containsExpand || containsCompress)) {
      if (!this.mainPanel.nativeElement.classList.contains('content-area-is-expanded')) {
        this.renderer.removeClass(this.mainPanel.nativeElement, 'scale-content-area-expand');
        this.renderer.addClass(this.mainPanel.nativeElement, 'content-area-is-expanded');
      } else {
        this.renderer.removeClass(this.mainPanel.nativeElement, 'content-area-is-expanded');
        this.renderer.removeClass(this.mainPanel.nativeElement, 'scale-content-area-compress');
      }

      this.renderer.removeStyle(this.mainPanel.nativeElement, 'transform');
    }
  }
}
