import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SecurityContext,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfo, SIDEBAR_ROUTES } from '../../../routing/routes/sidebar.routes';
import { ImageService } from '../../../services/ui/image.service';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SidebarService } from '../../../services/ui/sidebar.service';
import { AppConfigService } from '../../../services/app-config.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('parentNavItem') public parentNavItem!: ElementRef<HTMLElement>;
  @ViewChildren('parentListItem') public parentListItem!: QueryList<ElementRef<HTMLElement>>;
  @Input() public aside!: HTMLElement;
  @Input() public mainPanel!: HTMLElement;
  @Input() public events: Subject<TransitionEvent>;
  public menuItems: RouteInfo[];
  public SecurityContext = SecurityContext;

  private readonly subs = new SubSink();

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public imageService: ImageService,
    public sidebarService: SidebarService,
    private readonly router: Router,
    private readonly appConfigService: AppConfigService,
    private readonly renderer: Renderer2,
  ) {
    this.appConfigService
      .getUiConfigData()
      .pipe(take(1))
      .subscribe((uiConfig) => {
        this.sidebarService.sidebarCompressedWidth = uiConfig.sidebarCompressedWidth;
        this.sidebarService.sidebarEnlargedWidth = uiConfig.sidebarEnlargedWidth;
        this.sidebarService.setRouteData(router.url);
      });
  }

  ngOnInit(): void {
    this.menuItems = SIDEBAR_ROUTES.filter((menuItem) => menuItem);
  }

  ngAfterViewInit(): void {
    let subMenuOpened = false;
    this.parentListItem.forEach((el) => {
      if (el.nativeElement.classList.contains(this.sidebarService.currentRoute)) {
        if (el.nativeElement.classList.contains('has-submenu')) {
          this.renderer.addClass(el.nativeElement, 'expanded');
          subMenuOpened = true;
        }
        this.renderer.addClass(el.nativeElement, 'active');
      }
    });

    if (!subMenuOpened) {
      this.renderer.addClass(this.parentNavItem.nativeElement, 'all-sub-menus-closed');
    }

    this.initSidebarEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public toggleMenu(parentListEl: HTMLElement): void {
    const item = parentListEl.getAttribute('data-item');
    this.sidebarService.closeMenus(item, this.parentListItem, this.parentNavItem);

    const classList = parentListEl.classList;
    if (classList.contains('expanded')) {
      parentListEl.classList.remove('expanded');
      this.renderer.addClass(this.parentNavItem.nativeElement, 'all-sub-menus-closed');
    } else {
      parentListEl.classList.add('expanded');
      this.renderer.removeClass(this.parentNavItem.nativeElement, 'all-sub-menus-closed');
    }
  }

  public setParentAsActive(parentListEl: HTMLElement): void {
    this.removeActiveClass();
    this.renderer.addClass(parentListEl, 'active');
  }

  public goTo(path: string, parentListEl: HTMLElement): void {
    this.removeActiveClass();
    void this.router.navigate([path]).then((success) => {
      if (success) {
        const item = parentListEl.getAttribute('data-item');
        this.sidebarService.closeMenus(item, this.parentListItem, this.parentNavItem);

        this.renderer.addClass(parentListEl, 'active');
      }
    });
  }

  private removeActiveClass(): void {
    this.parentListItem.forEach((el) => {
      this.renderer.removeClass(el.nativeElement, 'active');
    });
  }

  private initSidebarEvents(): void {
    this.subs.sink = this.events
      .pipe(filter((e) => e.propertyName === 'transform'))
      .subscribe(() => {
        if (this.sidebarService.isCompressed) {
          this.renderer.addClass(this.aside, 'compressed');
          this.renderer.removeClass(this.aside, 'expand-sidebar');
        } else {
          this.renderer.removeClass(this.aside, 'compressed');
          this.renderer.addClass(this.aside, 'expand-sidebar');
        }

        this.renderer.removeClass(this.aside, 'collapse-sidebar');
        this.sidebarService.invertScale('none', this.mainPanel);
      });
  }
}
