import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducer, initialState } from './state/auth.reducer';
import { AuthEffects } from './state/auth.effects';
import { AUTH_KEY } from './state/auth.selectors';

@NgModule({
  declarations: [AuthComponent, RegisterComponent, LoginComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(AUTH_KEY, authReducer, { initialState }),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthModule {}
