import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cookeHaederName = 'X-CSRFToken';
    const csrfToken = this.tokenExtractor.getToken();
    if (csrfToken !== null && !req.headers.has(cookeHaederName)) {
      req = req.clone({ headers: req.headers.set(cookeHaederName, csrfToken) });
    }
    return next.handle(req);
  }
}
