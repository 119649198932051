import { Pipe, PipeTransform, inject } from '@angular/core';
import { TimeBlockProjectModel } from '../../../../../core/models/timeblock/time-block.model';
import Color from 'color';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import {
  TimeBlockContentType,
  TimeBlockType,
} from '../../../../../shared/data-types/time-block-types';
import { TimeBlockService } from '../services/time-block.service';

@Pipe({
  name: 'timeBlockColor',
})
export class TimeBlockColorPipe implements PipeTransform {
  private readonly timeBlockService = inject(TimeBlockService);

  transform(timeBlockComponentItem: ITimeBlockComponentItem, brightness = 0): string {
    if (
      timeBlockComponentItem.timeBlockModel.type === TimeBlockType.NonExistingBlock ||
      this.timeBlockService.isDefaultTimeBlock(timeBlockComponentItem)
    ) {
      return '';
    }

    if (timeBlockComponentItem.timeBlockContentType === TimeBlockContentType.Project) {
      return this.getTimeBlockProjectColor(timeBlockComponentItem, brightness);
    }
  }

  private getTimeBlockProjectColor(
    timeBlockComponentItem: ITimeBlockComponentItem,
    brightness: number,
  ): string {
    const timeBlockModel = timeBlockComponentItem.timeBlockModel as TimeBlockProjectModel;
    const colorStr = timeBlockModel.project.color.value;
    if (timeBlockModel.isFullday) {
      return colorStr;
    }

    const color = Color(colorStr);
    return color.lighten(brightness).rgb().toString();
  }
}
