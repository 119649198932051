import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from '../../../services/endpoints/endpoint.service';
import { HttpErrorService } from '../../../services/http-error.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpOperation } from '../../../enums/http-operation';
import * as fromTimerActions from './timer.actions';
import * as fromUserActions from '../../users/users.actions';
import { TimeBlockContentType } from '../../../../shared/data-types/time-block-types';
import { TimeBlockHttpTransformationService } from '../../../../features/calendar/components/time-block/http/time-block-http-transformation.service';
import { DateTimeHelper } from '../../../../features/calendar/util/date-time-helper';

@Injectable()
export class TimerEffects {
  postTimer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTimerActions.postTimer),
      map((action) => action.payload),
      switchMap((timerStatus) => {
        const endpoint = this.endpointService.getTimerSlug(HttpOperation.Get);
        const time = DateTimeHelper.dateToString(
          DateTimeHelper.setSecondsAndMillisToZero(DateTimeHelper.utcDate()),
        );

        return this.http.post<{ timeblock }>(endpoint, { status: timerStatus, time }).pipe(
          map((response) => {
            const timeBlockItem = this.timeBlockHttpTransformationService.toTimeBlockItems(
              response.timeblock,
              TimeBlockContentType.Project,
              HttpOperation.Post,
            )[0];

            return fromTimerActions.timerPosted({
              payload: {
                status: timerStatus,
                generatedTimeBlock: timeBlockItem,
              },
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Timer',
              fromTimerActions.postTimer,
              fromUserActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
    private readonly timeBlockHttpTransformationService: TimeBlockHttpTransformationService,
  ) {}
}
