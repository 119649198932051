import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '../../services/http-error.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { EndpointService } from '../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../enums/http-operation';
import { ClientModel } from '../../models/client/client.model';
import * as fromClientsActions from '../clients/clients.actions';
import { forkJoin } from 'rxjs';

@Injectable()
export class ClientsEffects {
  fetchClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromClientsActions.fetchClientList),
      switchMap(() => {
        const endpoint = this.endpointService.getClientsSlug(HttpOperation.Get);
        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown[]) => {
            const clients = plainToInstance(ClientModel, json);
            return fromClientsActions.setClients({ payload: clients });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Clients',
              fromClientsActions.fetchClientList,
              fromClientsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchSingleClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromClientsActions.fetchSingleClient),
      map((data) => data.payload),
      switchMap((clientId) => {
        const endpoint = this.endpointService.getClientsSlug(HttpOperation.Get, clientId);

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const client = plainToInstance(ClientModel, json);
            return fromClientsActions.setSingleClient({ payload: client });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Clients',
              fromClientsActions.fetchSingleClient,
              fromClientsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromClientsActions.addClient),
      map((action) => action.payload),
      mergeMap((clientModel) => {
        const endpoint = this.endpointService.getClientsSlug(HttpOperation.Post);
        return this.http.post<ClientModel | ClientModel[]>(endpoint, clientModel).pipe(
          map((clients) => {
            return fromClientsActions.clientAdded({ payload: clients });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Clients',
              fromClientsActions.addClient,
              fromClientsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromClientsActions.updateClient),
      map((action) => action.payload),
      mergeMap((clientModel) => {
        let requests;
        let endpoint;
        if (Array.isArray(clientModel)) {
          requests = clientModel.map((model) => {
            endpoint = this.endpointService.getClientsSlug(HttpOperation.Delete, model.id);
            return this.http.put(endpoint, instanceToPlain(clientModel));
          });
        } else {
          endpoint = this.endpointService.getClientsSlug(HttpOperation.Delete, clientModel.id);
          requests = [this.http.put(endpoint, instanceToPlain(clientModel))];
        }

        return forkJoin(requests).pipe(
          map((client: ClientModel[]) => {
            return fromClientsActions.clientUpdated({ payload: client });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Clients',
              fromClientsActions.updateClient,
              fromClientsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromClientsActions.deleteClient),
      map((action) => action.payload),
      mergeMap((clients) => {
        let requests;
        let endpoint;

        if (Array.isArray(clients)) {
          requests = clients.map((model) => {
            endpoint = this.endpointService.getClientsSlug(HttpOperation.Delete, model.id);
            return this.http.delete(endpoint, model.id);
          });
        } else {
          endpoint = this.endpointService.getClientsSlug(HttpOperation.Delete, clients.id);
          requests = [this.http.delete(endpoint, clients.id)];
        }

        return forkJoin(requests).pipe(
          map(() => {
            return fromClientsActions.clientDeleted({ payload: clients });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Clients',
              fromClientsActions.deleteClient,
              fromClientsActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
