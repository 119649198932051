import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public isCustomIcon(icon: string): boolean {
    const extension = icon.split('.')[1];
    return typeof extension !== 'undefined';
  }
}
