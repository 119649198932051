import { Pipe, PipeTransform } from '@angular/core';
import {
  TimeBlockModel,
  TimeBlockProjectModel,
} from '../../../../../core/models/timeblock/time-block.model';
import { ColorBrightness } from '../../../../../shared/data-types/color-types';
import Color from 'color';
import { CSSDefaultProjectBGColor } from '../../../../../core/data-repository/css-constants';

@Pipe({
  name: 'timeBlockBGColor',
})
export class TimeBlockBgColorPipe implements PipeTransform {
  transform(
    timeBlockModel: TimeBlockModel,
    brightness: ColorBrightness = ColorBrightness.Standard,
  ): string {
    let colorStr = CSSDefaultProjectBGColor;
    if (!(timeBlockModel instanceof TimeBlockProjectModel)) {
      return;
    }

    if (!timeBlockModel.project) {
      return colorStr;
    }

    colorStr = timeBlockModel.project.color.value;
    if (timeBlockModel.isFullday) {
      return colorStr;
    }

    const color = Color(colorStr);
    if (brightness === ColorBrightness.Brighter) {
      return color.lighten(0.6).rgb().toString();
    }

    return color.rgb().toString();
  }
}
