import { Pipe, PipeTransform } from '@angular/core';
import { TimeBlockModel } from '../../../../../core/models/timeblock/time-block.model';
import { DateTimeHelper } from '../../../util/date-time-helper';

@Pipe({
  name: 'timeBlockFilter',
})
export class TimeBlockFilterPipe implements PipeTransform {
  transform(blocks: Map<string, TimeBlockModel[]>, day: Date): TimeBlockModel[] {
    const date = DateTimeHelper.format(day);
    if (blocks?.has(date)) {
      return blocks.get(date);
    }
    return null;
  }
}
