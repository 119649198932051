import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { Actions, ofType } from '@ngrx/effects';
import * as fromHttpErrorActions from './core/state/http-error/http-error.actions';
import {
  NotificationType,
  ShiveNotificationService,
} from './core/services/controls/shive-notification.service';
import 'reflect-metadata';
import { Logger } from './shared/logging/logger';
import { environment } from '../environments/environment';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly times = [];
  private fps;
  private readonly subs = new SubSink();

  constructor(
    private readonly actions$: Actions,
    private readonly shiveNotificationService: ShiveNotificationService,
  ) {
    this.subs.sink = this.actions$
      .pipe(ofType(fromHttpErrorActions.addHttpError))
      .subscribe((error) => {
        this.shiveNotificationService.show(NotificationType.Error, error.payload.message);
      });

    // Standard logger
    Logger.toggleLogging(environment.logging);

    if (environment.logging) {
      LogRocket.init('0appxw/shive');
    }
  }

  public render(): void {
    window.requestAnimationFrame(() => {
      const now = performance.now();
      while (this.times.length > 0 && this.times[0] <= now - 1000) {
        this.times.shift();
      }
      this.times.push(now);
      this.fps = this.times.length;
      document.getElementById('fps').textContent = this.fps;
      this.render();
    });
  }

  ngOnInit(): void {
    // if (!environment.production) {
    //   this.render();
    // }
    this.render();
    // throw new CustomError('Error', 'test');
    //
    // history$.subscribe(test => {
    //   console.debug('ok');
    // });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
