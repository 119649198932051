import { Pipe, PipeTransform } from '@angular/core';
import { TimeBlockModel } from '../../../../../core/models/timeblock/time-block.model';
import { DateTimeHelper } from '../../../util/date-time-helper';

@Pipe({
  name: 'timeBlockTimeDifference',
})
export class TimeBlockTimeDifferencePipe implements PipeTransform {
  transform(timeBlockModel: TimeBlockModel): string {
    const start = timeBlockModel.start;
    const end = DateTimeHelper.transformToExactlyMidnightIfNeeded(timeBlockModel.end, true);
    if (DateTimeHelper.isBefore(end, start)) {
      throw new Error('End date is before start date.');
    }

    let days = 0;
    let hours = 0;
    let minutes = 0;
    const duration = DateTimeHelper.intervalToDuration({ start, end });

    if (typeof duration.days !== 'undefined') {
      days = duration.days;
    }

    if (typeof duration.hours !== 'undefined') {
      hours = duration.hours;
    }

    if (typeof duration.minutes !== 'undefined') {
      minutes = duration.minutes;
    }

    hours = days * 24 + hours;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}
