import { createReducer, on } from '@ngrx/store';
import {
  absenceReasonAdded,
  absenceReasonDeleted,
  absenceReasonUpdated,
  setAbsenceReasons,
} from './absence-reasons.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { AbsenceReasonModel } from '../../../models/absence-reason/absence-reason.model';

export interface AbsenceReasonState extends EntityState<AbsenceReasonModel> {}

export const absenceReasonAdapter: EntityAdapter<AbsenceReasonModel> =
  createEntityAdapter<AbsenceReasonModel>({
    selectId: (entity: AbsenceReasonModel) => entity.id,
    sortComparer: false,
  });
const initialState: AbsenceReasonState = absenceReasonAdapter.getInitialState();

export const absenceReasonsReducer = createReducer(
  initialState,
  on(setAbsenceReasons, (state, { payload }): AbsenceReasonState => {
    return absenceReasonAdapter.setAll(payload, state);
  }),
  on(absenceReasonAdded, (state, { payload }): AbsenceReasonState => {
    return absenceReasonAdapter.addOne(payload, state);
  }),
  on(absenceReasonUpdated, (state, { payload }): AbsenceReasonState => {
    return absenceReasonAdapter.upsertOne(payload, state);
  }),
  on(absenceReasonDeleted, (state, { payload }): AbsenceReasonState => {
    return absenceReasonAdapter.removeOne(payload.id, state);
  }),
);
