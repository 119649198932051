import { createReducer, on } from '@ngrx/store';
import {
  loginUser,
  registerUser,
  setLoggedInUser,
  userLoggedIn,
  userRegistered,
} from './auth.actions';
import { AuthState } from '../../shared/data-types/auth-types';

export const initialState: AuthState = {
  user: null,
  isLoading: false,
};
export const authReducer = createReducer(
  initialState,
  on(loginUser, (state) => {
    return {
      ...state,
      user: null,
      isLoading: true,
    };
  }),
  on(userLoggedIn, (state) => {
    return {
      ...state,
      user: null,
      isLoading: false,
    };
  }),
  on(registerUser, (state) => {
    return {
      ...state,
      user: null,
      isLoading: true,
    };
  }),
  on(userRegistered, (state) => {
    return {
      ...state,
      user: null,
      isLoading: false,
    };
  }),
  on(setLoggedInUser, (state, { payload }) => {
    return {
      ...state,
      user: payload,
      isLoading: false,
    };
  }),
);
