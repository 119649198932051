import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { SidebarFooterComponent } from './ui-components/template-parts/sidebar/sidebar-footer/sidebar-footer.component';
import { SidebarComponent } from './ui-components/template-parts/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { AdminLayoutRoutes } from './routing/routes/admin-layout.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { effects, initialState, metaReducers, reducers } from './state/app.state';
import { StoreModule } from '@ngrx/store';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminLayoutComponent } from './ui-components/admin-layout/admin-layout.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { PageFooterComponent } from './ui-components/template-parts/page-footer/page-footer.component';
import { PageHeaderComponent } from './ui-components/template-parts/page-header/page-header.component';
import { MemberNamePipe } from './pipes/member-name.pipe';
import { EmptyCalendarSpaceInteractionDirective } from '../features/calendar/components/time-block/interaction/user-interaction/empty-calendar-space-interaction.directive';
import { ShiveCurrencyPipe } from './pipes/shive-currency.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LinkbarComponent } from './ui-components/template-parts/linkbar/linkbar.component';
import { ShivePopupDirective } from './directives/shive-popup.directive';

@NgModule({
  declarations: [
    SidebarFooterComponent,
    LinkbarComponent,
    SidebarComponent,
    AdminLayoutComponent,
    SafeHtmlPipe,
    ShiveCurrencyPipe,
    PageFooterComponent,
    PageHeaderComponent,
    MemberNamePipe,
    EmptyCalendarSpaceInteractionDirective,
  ],
  exports: [
    SidebarComponent,
    SidebarFooterComponent,
    SafeHtmlPipe,
    MemberNamePipe,
    FormsModule,
    ReactiveFormsModule,
    PageFooterComponent,
    PageHeaderComponent,
    EmptyCalendarSpaceInteractionDirective,
    ShiveCurrencyPipe,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule.forChild(AdminLayoutRoutes),
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(reducers, {
      initialState,
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    NgOptimizedImage,
    FaIconComponent,
    ShivePopupDirective,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    CurrencyPipe,
    {
      provide: 'wsUri',
      useValue: 'ws://localhost:8002/ws/tracker/',
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
