<form [formGroup]="loginForm" class="container" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-9">
      <kendo-formfield>
        <kendo-floatinglabel text="Benutzername oder E-Mail" [style.width.%]="100">
          <kendo-textbox showErrorIcon="initial" id="userLogin" [formControlName]="'userLogin'">
          </kendo-textbox>
        </kendo-floatinglabel>
      </kendo-formfield>
    </div>
    <div class="col-3"></div>
  </div>

  <div class="row">
    <div class="col-9">
      <kendo-formfield>
        <kendo-floatinglabel text="Passwort" [style.width.%]="100">
          <kendo-textbox
            showErrorIcon="initial"
            #password
            id="password"
            [formControlName]="'password'"
          ></kendo-textbox>
        </kendo-floatinglabel>
      </kendo-formfield>
    </div>
    <div class="col-3 k-display-flex k-align-items-end k-justify-content-end">
      <div class="k-form-buttons">
        <button kendoButton themeColor="base" type="submit" size="large">Login</button>
      </div>
    </div>
  </div>
</form>

<div class="container small-font k-mt-5">
  <div class="row">
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <input type="checkbox" size="large" #stayLoggedIn kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="stayLoggedIn" text="Angemeldet bleiben">
          </kendo-label>
        </div>

        <div class="col-6 k-text-right" style="margin-top: 3px">
          <a href="#">Passwort vergessen?</a>
        </div>
      </div>
    </div>
  </div>
</div>
