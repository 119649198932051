import { pageMap } from '../mapping/page-mapping';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { PageKey } from '../page-keys';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  subRoutes?: RouteInfo[];
}

export const SIDEBAR_ROUTES: RouteInfo[] = [
  {
    path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.DashboardPageKeys.Dashboard),
    title: pageMap.getTitle(PageKey.DashboardPageKeys.Dashboard),
    icon: 'dashboard.svg',
    class: 'dashboard',
  },
  {
    path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.CalendarPageKeys.Calendar),
    title: pageMap.getTitle(PageKey.CalendarPageKeys.Calendar),
    icon: 'time-tracking.svg',
    class: 'time-tracking',
  },
  {
    path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ProjectPageKeys.Projects),
    title: pageMap.getTitle(PageKey.ProjectPageKeys.Projects),
    icon: 'projects.svg',
    class: 'projects',
    subRoutes: [
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ProjectPageKeys.ProjectOverview),
        title: pageMap.getTitle(PageKey.ProjectPageKeys.ProjectOverview),
        icon: '',
        class: 'list-project',
      },
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ProjectPageKeys.ProjectAdd),
        title: pageMap.getTitle(PageKey.ProjectPageKeys.ProjectAdd),
        icon: '',
        class: 'add-project',
      },
    ],
  },
  {
    path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ClientPageKeys.Clients),
    title: pageMap.getTitle(PageKey.ClientPageKeys.Clients),
    icon: 'clients.svg',
    class: 'clients',
    subRoutes: [
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ClientPageKeys.ClientList),
        title: pageMap.getTitle(PageKey.ClientPageKeys.ClientList),
        icon: '',
        class: 'show-clients',
      },
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.ClientPageKeys.ClientAdd),
        title: pageMap.getTitle(PageKey.ClientPageKeys.ClientAdd),
        icon: '',
        class: 'add-client',
      },
    ],
  },
  {
    path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.TeamPageKeys.Team),
    title: pageMap.getTitle(PageKey.TeamPageKeys.Team),
    icon: 'team.svg',
    class: 'team',
    subRoutes: [
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.TeamPageKeys.TeamOverview),
        title: pageMap.getTitle(PageKey.TeamPageKeys.TeamOverview),
        icon: '',
        class: 'list-team',
      },
      {
        path: UrlSegmentDelimiter + pageMap.getRoute(PageKey.TeamPageKeys.TeamAbsences),
        title: pageMap.getTitle(PageKey.TeamPageKeys.TeamAbsences),
        icon: '',
        class: 'absences',
      },
    ],
  },
  { path: '/finances', title: 'Finanzen', icon: 'finances.svg', class: 'finances' },
  { path: '/reports', title: 'Berichte', icon: 'reports.svg', class: 'reports' },
];
