import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfilePersonalDataComponent } from './user-profile/user-profile-base-data/user-profile-personal-data/user-profile-personal-data.component';
import { UserProfileBaseDataComponent } from './user-profile/user-profile-base-data/user-profile-base-data.component';
import { AfterValueChangedDirective } from '../../core/directives/after-value-changed.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { UserProfileEmploymentDataComponent } from './user-profile/user-profile-base-data/user-profile-employment-data/user-profile-employment-data.component';
import { CoreModule } from '../../core/core.module';
import { UserDetailDataStore } from './state-and-data-handling/user-detail-data.store';
import { UserProfileSalaryDataComponent } from './user-profile/user-profile-base-data/user-profile-salary-data/user-profile-salary-data.component';
import { UserProfileAttachmentsComponent } from './user-profile/user-profile-attachments/user-profile-attachments.component';
import { UserProfileSocialSecurityComponent } from './user-profile/user-profile-base-data/user-profile-social-security/user-profile-social-security.component';
import { UserProfileAbsenceOverviewComponent } from './user-profile/user-profile-absence-overview/user-profile-absence-overview.component';
import { UserProfileWorkingHoursComponent } from './user-profile/user-profile-working-hours/user-profile-working-hours.component';

@NgModule({
  declarations: [
    UserProfileComponent,
    UserProfileBaseDataComponent,
    UserProfilePersonalDataComponent,
    UserProfileEmploymentDataComponent,
    UserProfileSalaryDataComponent,
    UserProfileAttachmentsComponent,
    UserProfileAbsenceOverviewComponent,
    UserProfileWorkingHoursComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AfterValueChangedDirective,
    FaIconComponent,
    NgOptimizedImage,
    CoreModule,
    UserProfileSocialSecurityComponent,
  ],
  providers: [UserDetailDataStore],
})
export class UserModule {}
