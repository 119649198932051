import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { ControlBarComponent } from './components/control-bar/control-bar.component';
import { NowIndicatorDirective } from './components/now-indicator/now-indicator.directive';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { WeekViewComponent } from './views/week/week-view.component';
import { DayViewComponent } from './views/day/day-view.component';
import { MonthViewComponent } from './views/month/month-view.component';
import { WINDOW_PROVIDERS } from './util/browser-window-ref';
import { CalendarInteractionDirective } from './interaction/calendar-interaction.directive';
import { CalendarInteractionService } from './interaction/calendar-interaction.service';
import { CalendarService } from './services/calendar.service';
import { TimeEntryDialogComponent } from './dialogs/time-entry-dialog/time-entry-dialog.component';
import { NewTimeBlockGenerationService } from './components/time-block/generation/new-time-block-generation.service';
import { CalendarMouseHandlerService } from './mouse/calendar-mouse-handler.service';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { MonthAndYearPipe } from './pipes/month-and-year.pipe';
import { HourAndMinutePipe } from './pipes/hour-and-minute.pipe';
import { ProjectTypeDialogComponent } from './dialogs/time-entry-dialog/project-type-dialog/project-type-dialog.component';
import { TimeOffTypeDialogComponent } from './dialogs/time-entry-dialog/time-off-type-dialog/time-off-type-dialog.component';
import { CalendarViewHandlerService } from './services/calendar-view-handler.service';
import { TimeBlockModule } from './components/time-block/time-block.module';
import { CalendarGeometryService } from './services/calendar-geometry.service';
import { TimeBlockDurationCalculationService } from './components/time-block/calculation/duration/time-block-duration-calculation.service';
import { DialogDateAndTimeInputComponent } from './dialogs/time-entry-dialog/shared/dialog-date-and-time-input/dialog-date-and-time-input.component';
import { DialogHttpRequestHandlerService } from './components/time-block/http/dialog-http-request-handler.service';
import { CalendarHeaderService } from './components/calendar-header/calendar-header.service';
import { CalendarScrollbarService } from './services/calendar-scrollbar.service';
import { OverflowingTimeBlockMonthViewDialogComponent } from './dialogs/month-view-dialog/overflowing-time-block-month-view/overflowing-time-block-month-view-dialog/overflowing-time-block-month-view-dialog.component';
import { AfterValueChangedDirective } from '../../core/directives/after-value-changed.directive';
import { CalendarCronJobsService } from './services/calendar-cron-jobs.service';
import { ControlBarService } from './components/control-bar/control-bar.service';
import { ActiveTimeBlockTimerService } from './components/time-block/active/active-time-block-timer.service';
import { SharedActiveTimeBlockService } from './components/time-block/active/shared-active-time-block.service';
import { ShivePopupDirective } from '../../core/directives/shive-popup.directive';
import { TimeOffPopupComponent } from './dialogs/time-entry-dialog/time-off-type-dialog/time-off-popup/time-off-popup.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CalendarSkeletonWeekViewComponent } from '../../shared/components/skeletons/calendar/calendar-skeleton-week-view/calendar-skeleton-week-view.component';

@NgModule({
  imports: [
    TimeBlockModule,
    CommonModule,
    CoreModule,
    SharedModule,
    NgOptimizedImage,
    AfterValueChangedDirective,
    ShivePopupDirective,
    EditorModule,
    CalendarSkeletonWeekViewComponent,
  ],
  declarations: [
    CalendarComponent,
    CalendarHeaderComponent,
    ControlBarComponent,
    WeekViewComponent,
    NowIndicatorDirective,
    DayViewComponent,
    MonthViewComponent,
    CalendarInteractionDirective,
    TimeEntryDialogComponent,
    MonthAndYearPipe,
    HourAndMinutePipe,
    ProjectTypeDialogComponent,
    TimeOffTypeDialogComponent,
    TimeOffPopupComponent,
    DialogDateAndTimeInputComponent,
    OverflowingTimeBlockMonthViewDialogComponent,
  ],
  exports: [CalendarComponent],
  providers: [
    WINDOW_PROVIDERS,
    CalendarInteractionService,
    NewTimeBlockGenerationService,
    CalendarService,
    ActiveTimeBlockTimerService,
    TimeBlockDurationCalculationService,
    CalendarGeometryService,
    CalendarViewHandlerService,
    ControlBarService,
    CalendarMouseHandlerService,
    CalendarCronJobsService,
    DialogHttpRequestHandlerService,
    CalendarHeaderService,
    CalendarScrollbarService,
    SharedActiveTimeBlockService,
  ],
})
export class CalendarModule {}
