import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromTasksActions from './tasks.actions';
import { ProjectTaskModel } from '../../../models/project/project-task.model';

@Injectable({
  providedIn: 'root',
})
export class TasksHttpService {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public getTasks(projectId?: number): Observable<readonly ProjectTaskModel[]> {
    this.store.dispatch(fromTasksActions.fetchTaskList({ payload: projectId }));

    return this.actions$.pipe(
      ofType(fromTasksActions.setTasks),
      map((result) => result.payload),
    );
  }

  public getSingleTask(id: number): Observable<ProjectTaskModel> {
    this.store.dispatch(
      fromTasksActions.fetchSingleTask({
        payload: id,
      }),
    );

    return this.actions$.pipe(
      ofType(fromTasksActions.setSingleTask),
      map((result) => result.payload),
    );
  }

  public addTask(ProjectTaskModel: ProjectTaskModel | ProjectTaskModel[]): Observable<unknown> {
    this.store.dispatch(
      fromTasksActions.addTask({
        payload: ProjectTaskModel,
      }),
    );

    return this.actions$.pipe(ofType(fromTasksActions.taskAdded));
  }

  public updateTask(ProjectTaskModel: ProjectTaskModel | ProjectTaskModel[]): Observable<unknown> {
    this.store.dispatch(
      fromTasksActions.updateTask({
        payload: ProjectTaskModel,
      }),
    );

    return this.actions$.pipe(ofType(fromTasksActions.taskUpdated));
  }

  public deleteTask(toBeDeletedTask: ProjectTaskModel | ProjectTaskModel[]): Observable<unknown> {
    this.store.dispatch(
      fromTasksActions.deleteTask({
        payload: toBeDeletedTask,
      }),
    );

    return this.actions$.pipe(ofType(fromTasksActions.taskDeleted));
  }
}
