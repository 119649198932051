import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProjectOrTaskStatusActions from './status.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EndpointService } from '../../../services/endpoints/endpoint.service';
import { HttpErrorService } from '../../../services/http-error.service';
import { HttpOperation } from '../../../enums/http-operation';
import { StatusModel } from '../../../models/project/status.model';

@Injectable()
export class StatusEffects {
  fetchProjectOrTaskStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProjectOrTaskStatusActions.fetchStatus),
      map((action) => action.payload),
      switchMap((projectOrTaskType) => {
        const endpoint = this.endpointService.getProjectOrTaskStatusSlug(HttpOperation.Get);
        let params = new HttpParams();
        params = params.append('group', projectOrTaskType);

        return this.http.get(endpoint, { params }).pipe(
          map((json: unknown[]) => {
            const statusModel = plainToInstance(StatusModel, json);
            return fromProjectOrTaskStatusActions.setStatus({ payload: statusModel });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Project or task Status',
              fromProjectOrTaskStatusActions.setStatus,
              fromProjectOrTaskStatusActions,
              error,
            );
          }),
        );
      }),
    );
  });

  // addProjectOrTaskStatus$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fromProjectOrTaskStatusActions.addProjectStatus),
  //       map(action => action.payload),
  //       mergeMap((projectModel) => {
  //           const endpoint = this.endpointService.getProjectsSlug(HttpOperation.Post);
  //           const httpProjectModel = instanceToPlain(projectModel);
  //           delete httpProjectModel.id;
  //
  //           return this.http
  //             .post<unknown>(endpoint, httpProjectModel)
  //             .pipe(
  //               map((projectStatus: StatusModel) => {
  //                 return fromProjectOrTaskStatusActions.projectStatusAdded({payload: projectStatus});
  //               }),
  //               catchError((error) => {
  //                 return this.httpErrorService.handleError(
  //                   'Project Status',
  //                   fromProjectOrTaskStatusActions.addProjectStatus,
  //                   fromProjectOrTaskStatusActions,
  //                   error
  //                 );
  //               })
  //             );
  //         }
  //       )
  //     );
  //   });
  //
  // updateProjectOrTaskStatus$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fromProjectOrTaskStatusActions.updateProjectStatus),
  //       map(action => action.payload),
  //       mergeMap((projectModel) => {
  //           let requests;
  //           let endpoint;
  //           if (Array.isArray(projectModel)) {
  //             requests = projectModel.map(model => {
  //               endpoint = this.endpointService.getProjectsSlug(HttpOperation.Put, model.id);
  //               return this.http.put(endpoint, instanceToPlain(model));
  //             });
  //           } else {
  //             endpoint = this.endpointService.getProjectsSlug(HttpOperation.Put, projectModel.id);
  //             requests = [this.http.put(endpoint, instanceToPlain(projectModel))];
  //           }
  //
  //           return forkJoin(requests)
  //             .pipe(
  //               map((project: Array<StatusModel>) => {
  //                 return fromProjectOrTaskStatusActions.projectStatusUpdated({payload: project});
  //               }),
  //               catchError((error) => {
  //                 return this.httpErrorService.handleError(
  //                   'Project Status',
  //                   fromProjectOrTaskStatusActions.updateProjectStatus,
  //                   fromProjectOrTaskStatusActions,
  //                   error
  //                 );
  //               })
  //             );
  //         }
  //       )
  //     );
  //   });
  //
  // deleteProjectTaskStatus$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fromProjectOrTaskStatusActions.deleteProjectStatus),
  //       map(action => action.payload),
  //       mergeMap((projectModel) => {
  //           let requests;
  //           let endpoint;
  //           if (Array.isArray(projectModel)) {
  //             requests = projectModel.map(model => {
  //               endpoint = this.endpointService.getProjectsSlug(HttpOperation.Delete, model.id);
  //               return this.http.delete(endpoint, model.id);
  //             });
  //           } else {
  //             endpoint = this.endpointService.getProjectsSlug(HttpOperation.Delete, projectModel.id);
  //             requests = [this.http.delete(endpoint, projectModel.id)];
  //           }
  //
  //           return forkJoin(requests)
  //             .pipe(
  //               map(() => {
  //                 return fromProjectOrTaskStatusActions.projectStatusDeleted({payload: projectModel});
  //               }),
  //               catchError((error) => {
  //                 return this.httpErrorService.handleError(
  //                   'Project Status',
  //                   fromProjectOrTaskStatusActions.deleteProjectStatus,
  //                   fromProjectOrTaskStatusActions,
  //                   error
  //                 );
  //               })
  //             );
  //         }
  //       )
  //     );
  //   });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
