import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CalendarView } from '../../../../../../shared/data-types/calendar-types';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { DragPart } from '../../interaction/movement/drag-and-resize/drag-part';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { TimeBlockModel } from '../../../../../../core/models/timeblock/time-block.model';

@Component({
  selector: 'app-time-block-body',
  templateUrl: './time-block-body.component.html',
  styleUrl: './time-block-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBlockBodyComponent implements OnChanges, AfterViewInit {
  @ViewChild('timeBlockBody') public timeBlockBody: ElementRef<HTMLElement>;
  @Input() public calendarViewMode: CalendarView;
  @Input() public timeBlockComponentItem: ITimeBlockComponentItem;
  @Output() readonly timeBlockBodySet$ = new EventEmitter<ElementRef<HTMLElement>>();
  public readonly faClock = faClock;
  public readonly CalendarView = CalendarView;
  public readonly DragPart = DragPart;
  public model: TimeBlockModel;

  ngAfterViewInit(): void {
    this.timeBlockBodySet$.next(this.timeBlockBody);
  }

  ngOnChanges(): void {
    this.model = this.timeBlockComponentItem.timeBlockModel;
  }
}
