import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamComponent } from './team.component';
import { ListTeamComponent } from './list-team/list-team.component';
import { DetailTeamMemberComponent } from './detail-team-member/detail-team-member.component';
import { RouterOutlet } from '@angular/router';
import { BodyModule, GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { ShivePopupDirective } from '../../core/directives/shive-popup.directive';
import { AbsencesComponent } from './absences/absences.component';
import { VacationQuotaComponent } from './absences/vacation-insights/vacation-quota.component';
import { AbsencePlannerComponent } from './absences/absence-planner/absence-planner.component';
import { LeaveRequestsComponent } from './absences/leave-requests/leave-requests.component';

@NgModule({
  declarations: [
    TeamComponent,
    ListTeamComponent,
    DetailTeamMemberComponent,
    AbsencesComponent,
    AbsencePlannerComponent,
    VacationQuotaComponent,
    LeaveRequestsComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    BodyModule,
    ButtonModule,
    CoreModule,
    GridModule,
    SharedModule,
    SharedModule,
    ShivePopupDirective,
  ],
})
export class TeamModule {}
