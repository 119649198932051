<div class="form-wrapper">
  <form class="k-form" [formGroup]="projectTypeForm">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="project" text="Projekt"></kendo-label>
      <kendo-dropdownlist
        [data]="projects$ | async"
        textField="text"
        valueField="value"
        formControlName="project"
        (selectionChange)="projectChanged($event)"
        #project
      >
      </kendo-dropdownlist>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label class="!k-align-items-start" [for]="task" text="Aufgabe"></kendo-label>
      <kendo-dropdownlist
        [data]="tasks"
        textField="text"
        valueField="value"
        formControlName="task"
        #task
      >
      </kendo-dropdownlist>
      <kendo-formerror>Error: Full Name is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield orientation="horizontal">
      <kendo-label
        class="!k-align-items-start !k-justify-content-flex-start k-mt-2"
        [for]="description"
        text="Beschreibung"
      ></kendo-label>
      <kendo-textarea
        formControlName="description"
        style="height: calc(1.5em * 3)"
        resizable="auto"
        #description
      >
      </kendo-textarea>
    </kendo-formfield>

    <app-dialog-time-input
      [timeBlock]="selectedTimeBlock"
      [form]="projectTypeForm"
    ></app-dialog-time-input>
  </form>
</div>

<ng-container [ngTemplateOutlet]="footer"></ng-container>
