import { AfterViewInit, Component, inject } from '@angular/core';
import { ShivePopupService } from '../../../../../../core/services/controls/shive-popup.service';

@Component({
  selector: 'app-time-off-popup',
  templateUrl: './time-off-popup.component.html',
  styleUrl: './time-off-popup.component.scss',
})
export class TimeOffPopupComponent implements AfterViewInit {
  componentParams: any = -1;
  private readonly shivePopupService = inject(ShivePopupService);

  ngAfterViewInit(): void {
    this.shivePopupService.dataLoaded$.next(true);
  }
}
