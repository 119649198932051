import { Pipe, PipeTransform, inject } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockContentType } from '../../../../../shared/data-types/time-block-types';
import { TimeBlockProjectModel } from '../../../../../core/models/timeblock/time-block.model';
import { TimeBlockService } from '../services/time-block.service';
import Color from 'color';

@Pipe({
  name: 'timeBlockStripedBgImagePipe',
})
export class TimeBlockStripedBgImagePipe implements PipeTransform {
  private readonly timeBlockService = inject(TimeBlockService);

  transform(timeBlockComponentItem: ITimeBlockComponentItem): string {
    if (
      !timeBlockComponentItem.timeBlockModel.isActive ||
      this.timeBlockService.isDefaultTimeBlock(timeBlockComponentItem)
    ) {
      return '';
    }

    if (
      timeBlockComponentItem.timeBlockContentType === TimeBlockContentType.Project &&
      timeBlockComponentItem.timeBlockModel.isActive
    ) {
      const timeBlockModel = timeBlockComponentItem.timeBlockModel as TimeBlockProjectModel;
      const colorStr = timeBlockModel.project.color.value;

      const primaryColor = Color('#fff').rgb().toString();
      const lighterColor = Color(colorStr).lighten(0.5).rgb().toString();
      return `linear-gradient(135deg,${lighterColor} 25%,${primaryColor} 25%,${primaryColor} 50%,${lighterColor} 50%,${lighterColor} 75%,${primaryColor} 75%,${primaryColor} 100%)`;
    }
    return '';
  }
}
