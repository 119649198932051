import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorService } from '../../../services/http-error.service';
import * as fromTasksActions from './tasks.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { EndpointService } from '../../../services/endpoints/endpoint.service';
import { HttpOperation } from '../../../enums/http-operation';
import { ProjectTaskModel } from '../../../models/project/project-task.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable()
export class TasksEffects {
  fetchTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTasksActions.fetchTaskList),
      map((action) => action.payload),
      switchMap((projectId) => {
        const endpoint = this.endpointService.getTasksSlug(HttpOperation.Get);

        let params = new HttpParams();
        if (projectId) {
          params = params.append('project_id', projectId);
        }

        return this.http.get<unknown>(endpoint, { params }).pipe(
          map((json: unknown[]) => {
            const tasks = plainToInstance(ProjectTaskModel, json);
            return fromTasksActions.setTasks({ payload: tasks });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromTasksActions.fetchTaskList,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchSingleTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTasksActions.fetchSingleTask),
      map((data) => data.payload),
      switchMap((taskId) => {
        const endpoint = this.endpointService.getTasksSlug(HttpOperation.Get, taskId);

        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const task = plainToInstance(ProjectTaskModel, json);
            return fromTasksActions.setSingleTask({ payload: task });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromTasksActions.fetchSingleTask,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTasksActions.addTask),
      map((action) => action.payload),
      mergeMap((ProjectTaskModel) => {
        const endpoint = this.endpointService.getTasksSlug(HttpOperation.Post);
        let requests;
        if (Array.isArray(ProjectTaskModel)) {
          requests = ProjectTaskModel.map((tm) => this.http.post(endpoint, tm));
        } else {
          requests = [this.http.post(endpoint, ProjectTaskModel)];
        }

        return forkJoin(requests).pipe(
          map((tasks: ProjectTaskModel[]) => {
            return fromTasksActions.taskAdded({ payload: tasks });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromTasksActions.addTask,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTasksActions.updateTask),
      map((action) => action.payload),
      mergeMap((ProjectTaskModel) => {
        let requests;
        let endpoint;
        if (Array.isArray(ProjectTaskModel)) {
          requests = ProjectTaskModel.map((model) => {
            endpoint = this.endpointService.getTasksSlug(HttpOperation.Put, model.id);
            return this.http.put(endpoint, model);
          });
        } else {
          endpoint = this.endpointService.getTasksSlug(HttpOperation.Put, ProjectTaskModel.id);
          requests = [this.http.put(endpoint, ProjectTaskModel)];
        }

        return forkJoin(requests).pipe(
          map((tasks: ProjectTaskModel[]) => {
            return fromTasksActions.taskUpdated({ payload: tasks });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromTasksActions.updateTask,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  deleteTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromTasksActions.deleteTask),
      map((action) => action.payload),
      mergeMap((ProjectTaskModel) => {
        let requests;
        let endpoint;
        if (Array.isArray(ProjectTaskModel)) {
          requests = ProjectTaskModel.map((model) => {
            endpoint = this.endpointService.getTasksSlug(HttpOperation.Delete, model.id);
            return this.http.delete(endpoint, model.id);
          });
        } else {
          endpoint = this.endpointService.getTasksSlug(HttpOperation.Delete, ProjectTaskModel.id);
          requests = [this.http.delete(endpoint, ProjectTaskModel.id)];
        }

        return forkJoin(requests).pipe(
          map(() => {
            return fromTasksActions.taskDeleted({ payload: ProjectTaskModel });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Tasks',
              fromTasksActions.deleteTask,
              fromTasksActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
