import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { HttpErrorService } from '../../core/services/http-error.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { EndpointService } from '../../core/services/endpoints/endpoint.service';
import { UserModel } from '../../core/models/user/user.model';
import * as fromAuthActions from './auth.actions';
import { forkJoin, of } from 'rxjs';

@Injectable()
export class AuthEffects {
  fetchLoggedInUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthActions.fetchLoggedInUser),
      switchMap(() => {
        const endpoint = this.endpointService.authEndpointsService.getLoggedInUserSlug();
        return this.http.get(endpoint, { headers: { skipErrorInterceptor: 'true' } }).pipe(
          map((json) => {
            const loggedInUser = plainToInstance(UserModel, json);
            return fromAuthActions.setLoggedInUser({ payload: loggedInUser });
          }),
          catchError((error) => {
            if (error.status === HttpStatusCode.Forbidden) {
              return of(fromAuthActions.unauthorized());
            }
            return this.httpErrorService.handleError(
              'Auth',
              fromAuthActions.fetchLoggedInUser,
              fromAuthActions,
              error,
            );
          }),
        );
      }),
    );
  });

  registerUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthActions.registerUser),
      switchMap((registerModel) => {
        const endpoint = this.endpointService.authEndpointsService.getRegisterUserSlug();
        return this.http.post(endpoint, registerModel).pipe(
          map(() => {
            return fromAuthActions.userRegistered();
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Auth',
              fromAuthActions.registerUser,
              fromAuthActions,
              error,
            );
          }),
        );
      }),
    );
  });

  login$ = createEffect(() => {
    const csrfEndpoint = this.endpointService.authEndpointsService.getCSRFSlug();

    return this.actions$.pipe(
      ofType(fromAuthActions.loginUser),
      map((action) => action.payload),
      switchMap((loginModel) => {
        const rxjsObs$ = this.http.get(csrfEndpoint).pipe(
          catchError((error) => {
            if (error.status === HttpStatusCode.Forbidden) {
              return of(fromAuthActions.unauthorized());
            }
            return this.httpErrorService.handleError(
              'CSRF',
              fromAuthActions.loginUser,
              fromAuthActions,
              error,
            );
          }),
        );

        return forkJoin([of(loginModel), rxjsObs$]);
      }),
      switchMap(([loginModel]) => {
        const endpoint = this.endpointService.authEndpointsService.getLoginUserSlug();
        return this.http.post(endpoint, loginModel).pipe(
          map(() => {
            return fromAuthActions.userLoggedIn();
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Auth',
              fromAuthActions.userLoggedIn,
              fromAuthActions,
              error,
            );
          }),
        );
      }),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthActions.logoutUser),
      switchMap(() => {
        const endpoint = this.endpointService.authEndpointsService.getLoggedInUserSlug();
        return this.http.post<unknown>(endpoint, {}).pipe(
          map(() => {
            return fromAuthActions.userRegistered();
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Auth',
              fromAuthActions.userRegistered,
              fromAuthActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}
}
