import { AfterViewInit, Component, Input, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CalendarService } from '../../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../../../../components/time-block/time-block-component-items';
import { TimeBlockHttpTransformationService } from '../../../../components/time-block/http/time-block-http-transformation.service';

@Component({
  selector: 'app-dialog-time-input',
  templateUrl: './dialog-time-input.component.html',
  styleUrls: ['./dialog-time-input.component.scss'],
})
export class DialogTimeInputComponent implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() timeBlock: ITimeBlockComponentItem;
  private timeInputFieldsEnabled = false;

  constructor(
    public vcRef: ViewContainerRef,
    private readonly calendarService: CalendarService,
  ) {}

  ngAfterViewInit(): void {
    const dateToControl = this.form.controls.dateTo;
    const timeToControl = this.form.controls.timeTo;
    const fulldayCheckbox = this.form.controls.fullday;

    // Hide date to, time to and full day checkbox for active time blocks.
    if (this.timeBlock.timeBlockModel.isActive) {
      dateToControl.disable();
      timeToControl.disable();
      fulldayCheckbox.disable();
      return;
    }

    this.timeInputFieldsEnabled = this.timeBlock.timeBlockModel.isFullday;
    window.setTimeout(() => {
      this.toggleFullday();
      const end = TimeBlockHttpTransformationService.transformEndDate(
        this.timeBlock.timeBlockModel.end,
        false,
      );
      dateToControl.patchValue(end);
      timeToControl.patchValue(end);
    });
  }

  public toggleTimeInputFields(): void {
    this.timeInputFieldsEnabled = !this.timeInputFieldsEnabled;
    this.toggleFullday();
  }

  private toggleFullday(): void {
    if (this.timeInputFieldsEnabled) {
      this.form.controls.timeFrom.disable();
      this.form.controls.timeTo.disable();
      this.form.controls.timeFrom.patchValue(
        this.calendarService.model.calendarProperties.workTimeStart,
      );
      this.form.controls.timeTo.patchValue(
        this.calendarService.model.calendarProperties.workTimeEnd,
      );
    } else {
      this.form.controls.timeFrom.enable();
      this.form.controls.timeTo.enable();
      this.form.controls.timeFrom.patchValue(this.timeBlock.timeBlockModel.start);
      this.form.controls.timeTo.patchValue(this.timeBlock.timeBlockModel.end);
    }
  }
}
