import { Component, ElementRef, TemplateRef, ViewChild, inject } from '@angular/core';
import { ShiveRoutes } from '../../../../routing/segments/route-segments';
import { ShiveContextMenuComponent } from '../../../../../shared/components/context/shive-context-menu/shive-context-menu.component';
import { ShivePopupService } from '../../../../services/controls/shive-popup.service';
import { ContextMenuItem } from '../../../../../shared/data-types/context-menu-item-type';
import {
  faRightFromBracket,
  faUser,
  faUserGear,
  faUserPlus,
} from '@fortawesome/pro-light-svg-icons';
import { Router } from '@angular/router';
import * as config from 'src/app/shared/styles/config.json';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss'],
})
export class SidebarFooterComponent {
  @ViewChild('contextMenuHeader') contextMenuHeader: TemplateRef<HTMLElement>;
  @ViewChild('contextMenuFooter') contextMenuFooter: TemplateRef<HTMLElement>;
  @ViewChild('contextMenu') contextMenu: ElementRef<HTMLAnchorElement>;
  public readonly faRightFromBracket = faRightFromBracket;
  public readonly ShiveRoutes = ShiveRoutes;
  private readonly shivePopupService = inject(ShivePopupService);
  private readonly router = inject(Router);

  private readonly items: ContextMenuItem[] = [
    {
      text: 'Profildaten',
      faIcon: faUser,
      iconColorClass: 'turquoise',
      callback: () => {
        void this.router.navigate([ShiveRoutes.UserProfileBaseData, 1]);
      },
    },
    {
      text: 'Profileinstellungen',
      faIcon: faUserGear,
      iconColorClass: 'turquoise',
      callback: () => {},
    },
    {
      text: 'Neues Mitglied einladen',
      faIcon: faUserPlus,
      iconColorClass: 'turquoise',
      callback: () => {},
    },
  ];

  openContextMenu() {
    if (this.shivePopupService.popupRef) {
      this.shivePopupService.popupRef.close();
    }

    const popupRef = this.shivePopupService.open({
      content: ShiveContextMenuComponent,
      anchor: this.contextMenu.nativeElement,
      anchorAlign: { horizontal: 'right', vertical: 'bottom' },
      popupAlign: { horizontal: 'left', vertical: 'bottom' },
      margin: { horizontal: 20, vertical: 0 },
      popupClass: 'user-profile-context-menu context-menu arrow-left',
      animate: { type: 'fade', duration: +config.popupAnimationDuration, direction: 'right' },
    });
    const contextMenuComponent = popupRef.content.instance as ShiveContextMenuComponent;
    contextMenuComponent.items = this.items;
    contextMenuComponent.header = this.contextMenuHeader;
    contextMenuComponent.footer = this.contextMenuFooter;
  }
}
