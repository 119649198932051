import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { RegisterModel } from '../core/models/auth/register.model';
import { LoginModel } from '../core/models/auth/login.model';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAuthActions from './state/auth.actions';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  public isLoginMode = true;
  public errorMsg = null;
  public requestSent = false;

  private readonly subs = new SubSink();

  constructor(
    private readonly authService: AuthService,
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.requestSent = true;
    this.subs.sink = this.authService.authenticate().subscribe();

    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onSubmit(authModel: LoginModel | RegisterModel): void {
    this.requestSent = true;
    let authObs$: Observable<LoginModel | RegisterModel>;

    if (authModel instanceof LoginModel) {
      authObs$ = this.authService.login(authModel);
    } else {
      authObs$ = this.authService.register(authModel);
    }

    this.subs.sink = authObs$.pipe(switchMap(() => this.authService.authenticate())).subscribe();
  }

  private initEvents(): void {
    this.subs.sink = this.actions$.pipe(ofType(fromAuthActions.setLoggedInUser)).subscribe(() => {
      void this.router.navigate(['/time-tracking']);
    });

    this.subs.sink = this.actions$.pipe(ofType(fromAuthActions.unauthorized)).subscribe(() => {
      this.requestSent = false;
    });
  }
}
