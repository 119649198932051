import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { HttpOperation } from '../../enums/http-operation';
import { AuthEndpointsService } from './auth-endpoints.service';
import {
  AbsenceReasonsSlug,
  ClientsSlug,
  ColorsSlug,
  HolidayTemplatesSlug,
  ProjectsSlug,
  StatusSlug,
  TasksSlug,
  TimeBlocksSlug,
  TimerSlug,
  UsersSlug,
} from '../../data-repository/api-parts-querystrings';

@Injectable({
  providedIn: 'root',
})
export class EndpointService {
  private readonly PREFIX = environment.apiPath + UrlSegmentDelimiter;

  constructor(public authEndpointsService: AuthEndpointsService) {}

  // ////////////############ Time Blocks ############////////////
  public getTimeBlockSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + TimeBlocksSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(TimeBlocksSlug, operation, id);
  }

  // ////////////############ Clients ############////////////
  public getClientsSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + ClientsSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(ClientsSlug, operation, id);
  }

  // ////////////############ Projects ############////////////
  public getProjectsSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + ProjectsSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(ProjectsSlug, operation, id);
  }

  // ////////////############ Project or task Status ############////////////
  public getProjectOrTaskStatusSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + StatusSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(StatusSlug, operation, id);
  }

  // ////////////############ Tasks ############////////////
  public getTasksSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + TasksSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(TasksSlug, operation, id);
  }

  // ////////////############ Users ############////////////
  public getUsersSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + UsersSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(UsersSlug, operation, id);
  }

  // ////////////############ Colors ############////////////
  public getColorsSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + ColorsSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(ColorsSlug, operation, id);
  }

  // ////////////############ Timer ############////////////
  public getTimerSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + TimerSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(TimerSlug, operation, id);
  }

  // ////////////############ Absence Reasons ############////////////
  public getAbsenceReasonsSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + AbsenceReasonsSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(AbsenceReasonsSlug, operation, id);
  }

  // ////////////############ Holiday Templates ############////////////
  public getHolidayTemplatesSlug(operation: HttpOperation, id?: number): string {
    if (operation === HttpOperation.Post || (operation === HttpOperation.Get && !id)) {
      return this.PREFIX + HolidayTemplatesSlug + UrlSegmentDelimiter;
    }
    return this.buildUrlWithId(HolidayTemplatesSlug, operation, id);
  }

  private buildUrlWithId(slug: string, httpOperation: HttpOperation, id?: number): string | never {
    if (httpOperation !== HttpOperation.Get && (id === null || typeof id === 'undefined')) {
      throw new Error('Id not specified.');
    }
    return this.PREFIX + slug + UrlSegmentDelimiter + id + UrlSegmentDelimiter;
  }
}
