import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import * as fromHolidayTemplateActions from './holiday-templates.actions';
import { HttpOperation } from '../../../enums/http-operation';
import { HolidayTemplateModel } from '../../../models/settings/holiday/holiday-template.model';
import { EndpointService } from '../../../services/endpoints/endpoint.service';
import { HttpErrorService } from '../../../services/http-error.service';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class HolidayTemplatesEffects {
  fetchHolidayTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromHolidayTemplateActions.fetchHolidayTemplates),
      switchMap(() => {
        const endpoint = this.endpointService.getHolidayTemplatesSlug(HttpOperation.Get);
        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown[]) => {
            const holidayTemplates = plainToInstance(HolidayTemplateModel, json);
            return fromHolidayTemplateActions.setHolidayTemplates({ payload: holidayTemplates });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'HolidayTemplates',
              fromHolidayTemplateActions.setHolidayTemplates,
              fromHolidayTemplateActions,
              error,
            );
          }),
        );
      }),
    );
  });

  fetchHolidayTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromHolidayTemplateActions.fetchHolidayTemplate),
      map((action) => action.payload),
      switchMap((templateId) => {
        const endpoint = this.endpointService.getHolidayTemplatesSlug(
          HttpOperation.Get,
          templateId,
        );
        return this.http.get<unknown>(endpoint).pipe(
          map((json: unknown) => {
            const holidayTemplate = plainToInstance(HolidayTemplateModel, json);
            return fromHolidayTemplateActions.setHolidayTemplate({ payload: holidayTemplate });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'HolidayTemplates',
              fromHolidayTemplateActions.fetchHolidayTemplate,
              fromHolidayTemplateActions,
              error,
            );
          }),
        );
      }),
    );
  });

  addHolidayTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromHolidayTemplateActions.addHolidayTemplate),
      map((action) => action.payload),
      mergeMap((holidayTemplateModel) => {
        const endpoint = this.endpointService.getHolidayTemplatesSlug(HttpOperation.Post);
        const idLessHolidayTemplate = this.removeHolidayTemplateIds(holidayTemplateModel);

        return this.http.post(endpoint, instanceToPlain(idLessHolidayTemplate)).pipe(
          map((response) => {
            const holidayTemplateResponse = plainToInstance(HolidayTemplateModel, response);
            return fromHolidayTemplateActions.holidayTemplateAdded({
              payload: {
                newlyPersistedTemplate: holidayTemplateResponse,
                dummyTemplate: holidayTemplateModel,
              },
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'HolidayTemplates',
              fromHolidayTemplateActions.addHolidayTemplate,
              fromHolidayTemplateActions,
              error,
            );
          }),
        );
      }),
    );
  });

  updateHolidayTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromHolidayTemplateActions.updateHolidayTemplate),
      map((action) => action.payload),
      mergeMap((holidayTemplateModel) => {
        const endpoint = this.endpointService.getHolidayTemplatesSlug(
          HttpOperation.Put,
          holidayTemplateModel.id,
        );
        const idLessHolidayTemplate = this.removeHolidayTemplateIds(holidayTemplateModel);

        return this.http
          .put<HolidayTemplateModel>(endpoint, instanceToPlain(idLessHolidayTemplate))
          .pipe(
            map((holidayTemplateResponse) => {
              holidayTemplateResponse = plainToInstance(
                HolidayTemplateModel,
                holidayTemplateResponse,
              );
              return fromHolidayTemplateActions.holidayTemplateUpdated({
                payload: holidayTemplateResponse,
              });
            }),
            catchError((error) => {
              return this.httpErrorService.handleError(
                'HolidayTemplates',
                fromHolidayTemplateActions.updateHolidayTemplate,
                fromHolidayTemplateActions,
                error,
              );
            }),
          );
      }),
    );
  });

  deleteHolidayTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromHolidayTemplateActions.deleteHolidayTemplate),
      map((action) => action.payload),
      mergeMap((holidayTemplate) => {
        const endpoint = this.endpointService.getHolidayTemplatesSlug(
          HttpOperation.Delete,
          holidayTemplate.id,
        );
        return this.http.delete(endpoint).pipe(
          map(() => {
            return fromHolidayTemplateActions.holidayTemplateDeleted({
              payload: holidayTemplate,
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'HolidayTemplates',
              fromHolidayTemplateActions.deleteHolidayTemplate,
              fromHolidayTemplateActions,
              error,
            );
          }),
        );
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly endpointService: EndpointService,
    private readonly httpErrorService: HttpErrorService,
  ) {}

  private removeHolidayTemplateIds(template: HolidayTemplateModel): HolidayTemplateModel {
    const holidayTemplateClone = cloneDeep(template);
    if (holidayTemplateClone.id < 0) {
      delete holidayTemplateClone.id;
    }
    holidayTemplateClone.holidayEntries.forEach((holiday) => {
      if (holiday.id < 0) {
        delete holiday.id;
      }
    });

    holidayTemplateClone.members.forEach((user) => {
      if (user.id < 0) {
        delete user.id;
      }
    });
    return holidayTemplateClone;
  }
}
