import { Component, Input, OnInit } from '@angular/core';
import { TasksHttpService } from '../../../core/state/projects/tasks/tasks-http.service';
import { SubSink } from 'subsink';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProjectTaskModel } from '../../../core/models/project/project-task.model';

@Component({
  selector: 'app-list-task',
  templateUrl: './list-task.component.html',
  styleUrls: ['./list-task.component.scss'],
})
export class ListTaskComponent implements OnInit {
  @Input() public projectId: number;
  public addNewTask = false;
  public tasks: readonly ProjectTaskModel[] = [];
  public displayErroneousInputs = false;
  public displayedColumnsTasks: string[] = ['select', 'name', 'billable', 'controls'];
  public displayedColumnNewTask: string[] = [
    'new-select',
    'new-name',
    'new-billable',
    'new-controls',
  ];
  public newTaskForm: UntypedFormGroup;
  public isLoading = true;

  private readonly subs = new SubSink();

  constructor(
    private readonly taskService: TasksHttpService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.loadTasks();
    this.initForm();
  }

  private loadTasks(): void {
    this.subs.sink = this.taskService.getTasks().subscribe((tasks) => {
      this.tasks = tasks;
      this.isLoading = false;
    });
  }

  private initForm(): void {
    this.newTaskForm = this.formBuilder.group({
      newName: [''],
      newBillable: [''],
    });
  }
}
