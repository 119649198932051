import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { StatusModel } from '../../../models/project/status.model';
import {
  projectStatusAdded,
  projectStatusDeleted,
  projectStatusUpdated,
  setStatus,
} from './status.actions';

export interface ProjectState extends EntityState<StatusModel> {}

export const projectStatusAdapter: EntityAdapter<StatusModel> = createEntityAdapter<StatusModel>();
const initialState: ProjectState = projectStatusAdapter.getInitialState();

export const statusReducer = createReducer(
  initialState,
  on(setStatus, (state, { payload }): ProjectState => {
    return projectStatusAdapter.setMany(payload, state);
  }),
  on(projectStatusAdded, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectStatusAdapter.addMany(payload, state);
    }
    return projectStatusAdapter.addOne(payload, state);
  }),
  on(projectStatusUpdated, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectStatusAdapter.upsertMany(payload, state);
    }
    return projectStatusAdapter.upsertOne(payload, state);
  }),
  on(projectStatusDeleted, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectStatusAdapter.removeMany(
        payload.map((t) => t.id),
        state,
      );
    }
    return projectStatusAdapter.removeOne(payload.id, state);
  }),
);
