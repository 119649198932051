<div id="toggle-sidebar" class="k-d-flex k-align-self-end">
  <a
    class="custom-symbol slide-in text-white k-display-inline-block text-decoration-none user-select-none"
    (click)="sidebarService.toggleSidebar(document, aside, mainPanel)"
  >
  </a>
</div>

<section class="logo k-text-center">
  <img
    id="full-logo"
    ngSrc="../../../../.../../../../../assets/img/logo.svg"
    height="33"
    width="100"
    alt="logo"
    priority
  />
  <img
    id="icon-logo"
    ngSrc="../../../../.../../../../../assets/img/logo-icon.svg"
    class="d-none m-auto"
    height="175"
    width="107"
    alt="logo"
  />
</section>

<section class="sidebar-item-wrapper">
  <ul class="nav parentnav navbar-nav" #parentNavItem>
    @for (menuItem of menuItems; track menuItem) {
      <li
        #parentListItem
        [ngClass]="[menuItem.class, menuItem.subRoutes ? 'has-submenu' : '']"
        [attr.data-item]="menuItem.class"
        class="nav-item k-pos-relative"
      >
        <a
          class="nav-link !k-d-flex k-align-items-center"
          (click)="
            menuItem.subRoutes ? toggleMenu(parentListItem) : goTo(menuItem.path, parentListItem)
          "
        >
          <ng-container *ngTemplateOutlet="icon; context: { menuItem: menuItem }"> </ng-container>
          <p class="label ms-3">{{ menuItem.title }}</p>
        </a>
        @if (menuItem.subRoutes) {
          <ul class="nav subnav navbar-nav">
            @for (subItem of menuItem.subRoutes; track subItem) {
              <li>
                <a
                  [routerLink]="[subItem.path]"
                  (click)="setParentAsActive(parentListItem)"
                  routerLinkActive="active"
                  class="nav-link !k-d-flex k-align-items-center"
                >
                  @if (subItem.icon !== "") {
                    <ng-container *ngTemplateOutlet="icon; context: { menuItem: subItem }">
                    </ng-container>
                  }
                  <p class="label ms-3" [class.ms-3]="subItem.icon !== ''">{{ subItem.title }}</p>
                </a>
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>
</section>

<section id="control-area">
  <app-sidebar-footer></app-sidebar-footer>
</section>

<ng-template let-menuItem="menuItem" #icon>
  <span
    class="icon text-white d-inline-block text-decoration-none user-select-none"
    [ngStyle]="{
      'background-image': imageService.isCustomIcon(menuItem.icon)
        ? ('assets/img/navbar/' + menuItem.icon | safeHtml: SecurityContext.STYLE)
        : '',
    }"
  >
    @if (!imageService.isCustomIcon(menuItem.icon)) {
      {{ menuItem.icon }}
    }
  </span>
</ng-template>
