import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelper } from '../util/date-time-helper';

@Pipe({
  name: 'endOfDay',
})
export class EndOfDayPipe implements PipeTransform {
  transform(endDate: Date): Date {
    // If the end time is 23:59:59.9999, return 00:00:00 to keep visual consistency.
    return DateTimeHelper.transformToExactlyMidnightIfNeeded(endDate);
  }
}
