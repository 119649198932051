<div
  class="content-wrapper k-d-flex k-pl-3 k-pr-3 k-overflow-hidden k-pos-relative noselect tb"
  appEmptyCalendarSpaceInteraction
  #timeBlockBody
>
  <div
    class="event-time k-mt-2 tb"
    *ngIf="
      !timeBlockComponentItem.timeBlockModel.isFullday &&
      (calendarViewMode === CalendarView.DayGrid || calendarViewMode === CalendarView.WeekGrid)
    "
  >
    <div class="event-duration k-align-items-center k-justify-content-between k-w-full tb">
      <div class="start-and-end">
        <span class="value tb">{{
          timeBlockComponentItem.timeBlockModel.start | date: "HH:mm"
        }}</span>
        <span>&nbsp;—&nbsp;</span>
        <span class="value pr-1 tb">{{
          timeBlockComponentItem.timeBlockModel.end | endOfDay | date: "HH:mm"
        }}</span>
      </div>
      <div class="duration k-align-items-center k-d-flex">
        <fa-icon [icon]="faClock" [size]="'xs'" class="k-mt-0.5"></fa-icon>
        <span class="k-ml-1">{{
          timeBlockComponentItem.timeBlockModel | timeBlockTimeDifference
        }}</span>
      </div>
    </div>
  </div>

  <div
    class="text tb"
    [ngClass]="timeBlockComponentItem.timeBlockModel.isFullday ? '' : 'pt-2 pb-3'"
  >
    <div
      [style.background-color]="timeBlockComponentItem | timeBlockColor"
      *ngIf="
        !timeBlockComponentItem.timeBlockModel.isFullday &&
        calendarViewMode === CalendarView.MonthGrid
      "
      class="week-view-bullet-point"
    ></div>
    <h4
      class="title k-h-full tb"
      [style.background-color]="timeBlockComponentItem | timeBlockColor"
    >
      Name des Tasks {{ timeBlockComponentItem.id }}
    </h4>
    <div
      class="description k-h-full tb"
      *ngIf="
        !timeBlockComponentItem.timeBlockModel.isFullday &&
        (calendarViewMode === CalendarView.DayGrid || calendarViewMode === CalendarView.WeekGrid)
      "
    >
      Cur: {{ timeBlockComponentItem.id }} <br />
    </div>
  </div>
</div>
