<div class="px-3 py-2">
  <div id="vacation-info">
    <div class="font-weight-bold">Urlaub 2024</div>
    <div>
      <div class="grid">
        <div>Jahresurlaub:</div>
        <div>25 Tage</div>
      </div>
      <div class="grid">
        <div>Übertrag 2023:</div>
        <div>8 Tage</div>
      </div>
      <div class="grid font-weight-medium">
        <div>Gesamt 2024:</div>
        <div>33 Tage</div>
      </div>

      <br />

      <div class="grid">
        <div>Verbraucht:</div>
        <div>2 Tage</div>
      </div>
      <div class="grid">
        <div>Geplant:</div>
        <div>0 Tage</div>
      </div>
      <div class="grid font-weight-medium">
        <div>Noch übrig:</div>
        <div>23 Tage</div>
      </div>
    </div>
  </div>

  <div id="absence-days" class="k-mt-3">
    <div class="font-weight-bold">Abwesenheit 2024</div>
    <div>
      <div class="grid">
        <div>Abwesend:</div>
        <div>25 Tage</div>
      </div>
    </div>
  </div>
</div>
