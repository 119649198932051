import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../auth/state/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  canActivateChild():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate();
  }

  canActivate(): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.store.select(selectAuthState).pipe(
      filter((auth) => !auth.isLoading),
      map((authState) => {
        return authState.user;
      }),
      map((user) => {
        const isAuth = !!user;
        if (isAuth) {
          return true;
        }
        return this.router.createUrlTree(['/auth']);
      }),
    );
  }
}
