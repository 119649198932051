import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeBlockStructureService } from '../../../../time-block-structure/time-block-structure.service';
import { DragPart } from '../drag-part';
import {
  CalendarMouseHandlerService,
  ScrollData,
} from '../../../../../../mouse/calendar-mouse-handler.service';
import { Subject, merge, of, race, timer } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Position, PositionHelper } from '../../../../../../../../shared/data-structures/position';
import { SubSink } from 'subsink';
import { TouchAndMouseHandlerService } from '../../../../../../../../core/services/touch-and-mouse-handler.service';
import { environment } from '../../../../../../../../../environments/environment';
import { CalendarScrollbarService } from '../../../../../../services/calendar-scrollbar.service';
import { TimeBlockRenderService } from '../../../../rendering/time-block-render.service';
import { CSSPressedClass } from '../../../../../../../../core/data-repository/css-constants';
import { ITimeBlockComponentItem } from '../../../../time-block-component-items';
import {
  TimeBlockIndexSelector,
  TimeBlockType,
} from '../../../../../../../../shared/data-types/time-block-types';
import { TimeBlockDialogService } from '../../../../services/time-block-dialog.service';

@Directive({
  selector: '[appTimeBlockDragResize]',
})
export class TimeBlockDragResizeDirective implements OnInit, OnDestroy {
  @Input() public dragPart: DragPart = DragPart.None;
  @Input() public timeBlockId: number;
  @Input() public timeBlockPart: number;

  private static readonly curSelectedTimeBlock: TimeBlockIndexSelector = {
    id: -1,
    partNr: -1,
  };
  private static oldMousePos: Position = null;
  private readonly start$ = new Subject<MoveAction>();
  private readonly stop$ = new Subject<MoveAction>();
  private readonly tick = environment.mousePressedTriggerDuration;
  private readonly move$ = new Subject<MoveAction>();
  private readonly subs = new SubSink();

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly touchAndMouseHandlerService: TouchAndMouseHandlerService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockDialogService: TimeBlockDialogService,
  ) {}

  ngOnInit(): void {
    // Gets called when eiter start$ or move$ observable emits a value. Activates the time block when pressing the mouse on it.
    this.subs.sink = merge(this.start$, this.move$)
      .pipe(
        tap(() => {
          if (
            TimeBlockDragResizeDirective.curSelectedTimeBlock.id < 0 ||
            TimeBlockDragResizeDirective.curSelectedTimeBlock.partNr < 0
          ) {
            throw new Error(
              `Selected time block is invalid: ${
                TimeBlockDragResizeDirective.curSelectedTimeBlock.id
              }`,
            );
          }
        }),
        switchMap((interactionType) => {
          if (interactionType === MoveAction.StartTimer) {
            return race(
              timer(this.tick), // Handle long press
              this.stop$, // Cancel on stop event (mouseup)
            ).pipe(takeUntil(this.move$)); // Cancel on movement event
          }

          return of(-1); // Return empty observable for immediate movement
        }),
      )
      .subscribe(() => {
        // Start the dragging interaction and stop timer.
        const tbId = TimeBlockDragResizeDirective.curSelectedTimeBlock.id;
        const partNr = TimeBlockDragResizeDirective.curSelectedTimeBlock.partNr;

        // If a user clicks very quickly on a time block, the globalMouseReleased$ subject emits an event and set
        // id and partNr to -1. So just return then.
        if (tbId === -1) {
          return;
        }

        const selectedTimeBlock = this.timeBlockStructureService.retrieveTimeBlockPart(
          tbId,
          TimeBlockType.ExistingBlock,
          partNr,
        ) as ITimeBlockComponentItem;
        this.calendarMouseHandlerService.timeBlockPressed$.next(selectedTimeBlock);
        this.stopTimerAndUnsubscribe();
      });

    this.subs.sink = this.touchAndMouseHandlerService.globalMouseReleased$
      .pipe(filter(() => TimeBlockDragResizeDirective.curSelectedTimeBlock.id >= 0))
      .subscribe(() => {
        // Reset everything and stop the timer.
        this.timeBlockRenderService.removeClass(this.element.nativeElement, CSSPressedClass);
        TimeBlockDragResizeDirective.curSelectedTimeBlock.id = -1;
        TimeBlockDragResizeDirective.curSelectedTimeBlock.partNr = -1;
        TimeBlockDragResizeDirective.oldMousePos = null;
        this.stopTimerAndUnsubscribe();
      });

    this.subs.sink = merge(
      this.touchAndMouseHandlerService.globalMouseMoved$,
      this.calendarScrollbarService.calendarBodyScrollbar.wheelSubj$,
    )
      .pipe(filter(() => this.timeBlockId === TimeBlockDragResizeDirective.curSelectedTimeBlock.id))
      .subscribe((e) => {
        this.isTimeBlockMoving(e);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Fires when user clicks on a time block.
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    switch (e.button) {
      case 0:
        this.mousePointerDown(e);
        break;
    }
  }

  @HostListener('click', ['$event'])
  public mouseClick(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    const partNr = +this.timeBlockRenderService.getAttribute(e.currentTarget as Element, 'partNr');

    if (partNr === null) {
      throw new Error('Part number not set');
    }

    const selectedTimeBlockComponent = this.timeBlockStructureService.retrieveTimeBlockPart(
      this.timeBlockId,
      TimeBlockType.ExistingBlock,
      partNr,
    ) as ITimeBlockComponentItem;

    let selectedHTMLElementBlock: HTMLElement;

    if (
      this.element.nativeElement.classList.contains('top') ||
      this.element.nativeElement.classList.contains('left') ||
      this.element.nativeElement.classList.contains('bottom') ||
      this.element.nativeElement.classList.contains('right')
    ) {
      selectedHTMLElementBlock = this.element.nativeElement.parentElement.parentElement;
    } else {
      selectedHTMLElementBlock = this.element.nativeElement;
    }

    this.timeBlockDialogService.openExistingTimeBlockDialog(
      selectedHTMLElementBlock,
      selectedTimeBlockComponent,
    );
  }

  /**
   * Stop the timer and unsubscribe either when releasing the mouse or immediately after the interaction starts via mouse move.
   */
  private stopTimerAndUnsubscribe(): void {
    this.stop$.next(MoveAction.StopMoving);
    if (this.calendarMouseHandlerService.timeBlockPressedSub) {
      this.calendarMouseHandlerService.timeBlockPressedSub.unsubscribe();
    }
  }

  private mousePointerDown(e: MouseEvent): void {
    TimeBlockDragResizeDirective.curSelectedTimeBlock.id = this.timeBlockId;
    TimeBlockDragResizeDirective.curSelectedTimeBlock.partNr = this.timeBlockPart;
    TimeBlockDragResizeDirective.oldMousePos = {
      x: e.clientX,
      y: e.clientY,
    };

    this.start$.next(MoveAction.StartTimer);
    this.calendarMouseHandlerService.mousePosChanged(
      e.clientX,
      e.clientY + this.calendarScrollbarService.calendarBodyScrollbar.scrollData.scrollPosY,
      e.type,
    );
  }

  /**
   * Called as soon as a time block was selected and a user moves the mouse cursor or scrolls the mouse wheel.
   */
  private isTimeBlockMoving(e: MouseEvent | ScrollData): void {
    if (e instanceof MouseEvent) {
      e.preventDefault();
    }

    const tbId = TimeBlockDragResizeDirective.curSelectedTimeBlock.id;
    const partNr = TimeBlockDragResizeDirective.curSelectedTimeBlock.partNr;

    if (tbId < 0 || partNr < 0 || !TimeBlockDragResizeDirective.oldMousePos) {
      return;
    }

    let curMousePos: Position;
    if (e instanceof MouseEvent) {
      curMousePos = {
        x: e.clientX,
        y: e.clientY,
      };
    } else {
      curMousePos = {
        x: TimeBlockDragResizeDirective.oldMousePos.x,
        y: e.scrollPosY,
      };
    }

    if (!PositionHelper.compare(TimeBlockDragResizeDirective.oldMousePos, curMousePos, 10, 10)) {
      TimeBlockDragResizeDirective.oldMousePos = null;
      this.move$.next(MoveAction.ImmediatelyStartMoving);
    }
  }
}

enum MoveAction {
  StartTimer,
  ImmediatelyStartMoving,
  StopMoving,
}
