import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
import localeDe from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/de/all';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { ClientComponent } from './features/client/client.component';
import { CreateEditClientComponent } from './features/client/create-edit-client/create-edit-client.component';
import { ListClientComponent } from './features/client/list-client/list-client.component';
import { TaskComponent } from './features/task/task.component';
import { ListTaskComponent } from './features/task/list-task/list-task.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withXsrfConfiguration } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorInterceptorService } from './core/interceptors/http-error-interceptor.service';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { ClientDialogComponent } from './features/client/dialogs/client-dialog/client-dialog.component';
import { SharedModule } from './shared/shared.module';
import { CalendarModule } from './features/calendar/calendar.module';
import { CoreModule } from './core/core.module';
import 'hammerjs';
import { ProjectModule } from './features/project/project.module';
import { SettingsModule } from './features/settings/settings.module';
import { TeamModule } from './features/team/team.module';
import { UserModule } from './features/user/user.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ClientComponent,
    CreateEditClientComponent,
    ListClientComponent,
    TaskComponent,
    ListTaskComponent,
    ClientDialogComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule,
    CalendarModule,
    ProjectModule,
    UserModule,
    TeamModule,
    SettingsModule,
    CommonModule,
    InputsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          // Load all app data that is needed to run it.
          return appConfigService.loadAppConfig().subscribe();
        };
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: ICON_SETTINGS, useValue: { type: 'svg' } },
    provideHttpClient(
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'csrftoken',
      }),
    ),
  ],
})
export class AppModule {}
